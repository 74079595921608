import React, { useState } from 'react';
import emailjs from 'emailjs-com'
import Section from '../../../HOC/Section';
import  apiKeys  from '../../../api/apiKeys'

const Contact = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [number, setNumber] = useState('')

  function onSubmit(){
    const template_params = {
      "name": name,
      "email": email,
      'number':number,
      "message": message
    }

    const service_id = apiKeys.SERVICE_ID
    const template_id = apiKeys.TEMPLATE_ID;
    const user_id = apiKeys.USER_ID;

    emailjs.send(service_id, template_id, template_params, user_id)
    
    alert('Mensagem enviada! Em breve retornaremos!!!');

  }

  return (
    <Section id='contact'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Contate </span>- nos
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
            Caso tenha alguma dúvida, entre em contato conosco.          </h6>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-md-9 col-lg-7 mr-auto ml-auto'>
              <form onSubmit={onSubmit}>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control rounded-0'
                    aria-describedby='emailHelp'
                    placeholder='Nome Completo...'
                    value={name}
                    onChange={e => setName(e.target.value)}

                  />
                </div>
                <div className='form-group'>
                  <input
                    type='email'
                    className='form-control rounded-0'
                    aria-describedby='emailHelp'
                    placeholder='E-mail...'
                    value={email}
                    onChange={e => setEmail(e.target.value)}

                  />
                </div>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control rounded-0'
                    placeholder='Telefone...'
                    value={number}
                    onChange={e => setNumber(e.target.value)}

                  />
                </div>
                <div className='form-group'>
                  <textarea
                    className='form-control rounded-0'
                    rows='5'
                    placeholder='Mensagem...'
                    value={message}
                    onChange={e => setMessage(e.target.value)}

                  />
                </div>
                <div className='form-group text-center'>
                  <button type="submit" className='buttons'>
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Contact;
