import React from 'react';

import Section from '../../../HOC/Section';
import bgImage from '../../../assets/img/home_bg.jpg';
import Link from '../../UI/Link/Link';

const home = () => {
  return (
    <Section id='home'>
      <div>
        <div
          className='home-content p-5'
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          <div className='intro container text-center text-light'>
            <h1 className='title'>SEJA BEM VINDO AO UAIMOBI</h1>
            <h2 className='sub-title mb-4'>
            Somos uma empresa de Mobilidade Urbana. Assim como Uber e 99, gerenciamos o transporte no geral.
            </h2>
            <Link target='about' >
              <button className='buttons'>
                Sobre nós
              </button>
              </Link>
              <Link target='contact' >
              <button className='buttons-contact'>
                Contate-nos
              </button>
              </Link>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default home;
