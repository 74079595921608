import React, { Fragment } from 'react';

import Section from '../HOC/Section';
import Header from '../components/UI/Header/Header'
import Footer from '../components/UI/Footer/Footer'
const about = () => {
    return (
        <Fragment>
            <Header inHome={false} />
            <div>
                <div>
                    <Section id='pageAbout'>
                        <div className='container pt-2 pb-5'>
                            <div className='section-header pt-5 pb-5 text-center'>
                                <h3 className='title-page'>
                                    <span><br /><br /><br />
                                        Termos de uso para
                                    </span> Cadastro de Motorista
                                </h3>
                            </div>
                            <div className='row'>
                                <div className='about-description'>
                                    <p><span ><span ><strong>1. INTRODUÇÃO E DISPOSIÇÕES GERAIS</strong></span></span></p>
                                    <p><span ><span ><strong>1.1. Bem-vindo à UAI MOBI! </strong></span><span >Estes são os Termos de Uso (“</span><span ><u><strong>Termos</strong></u></span><span >”) que regem o acesso e uso, dentro do Brasil, dos serviços prestados através de aplicação tecnológica (“</span><span ><u><strong>Aplicativo</strong></u></span><span >”), sítios da Internet e conteúdos relacionados (“</span><span ><u><strong>Serviço(s)</strong></u></span><span >”), disponibilizados pela UAI MOBI Tecnologia. (“</span><span ><u><strong>UAI MOBI</strong></u></span><span >”), ou qualquer de suas afiliadas, ao(s) motorista(s) de táxi devidamente regularizado(s) para o exercício da profissão “</span><span ><u><strong>Taxista(s</strong></u></span><span ><strong>)</strong></span><span >” ou o(s) motorista(s) de transporte remunerado privado individual de passageiros e mototaxistas (“</span><span ><u><strong>Motorista(s) Parceiro(s)</strong></u></span><span >”) e, em conjunto com Taxista(s), “</span><span ><u><strong>Motorista(s) Parceiro(s)</strong></u></span><span >”), que decidam utilizar os Serviços da UAI MOBI.</span></span></p>
                                    <p><span ><span ><strong>1.1.1.</strong></span><span ><span > </span></span><span ><strong>POR FAVOR, LEIA COM ATENÇÃO ESTES TERMOS ANTES DE ACESSAR OU USAR OS SERVIÇOS.</strong></span></span></p>
                                    <p><span ><span ><strong>1.2.</strong></span><span ><span > </span></span><span ><strong>ACEITAÇÃO DOS TERMOS E CONDIÇÕES DE USO.</strong></span><span > AO SE CADASTRAR E UTILIZAR CONTINUAMENTE OS SERVIÇOS, O MOTORISTA PARCEIRO ESTARÁ DECLARANDO TER LIDO, ENTENDIDO E ACEITO OS TERMOS. CASO, A QUALQUER TEMPO, O MOTORISTA NÃO CONCORDE COM OS TERMOS, DEVERÁ CESSAR IMEDIATAMENTE A UTILIZAÇÃO DO APLICATIVO E DESINSTALÁ-LO DE SEU APARELHO.</span></span></p>
                                    <p><span ><span ><strong>1.2.1.</strong></span><span > Termos Adicionais poderão se aplicar a determinados Serviços, tais como condições para categorias específicas, veículos com opcionais, eventos, regulamentos, atividades, promoções em particular ou ações de recompensas, e esses Termos Adicionais serão divulgados em relação aos respectivos Serviços (“</span><span ><u><strong>Termos Adicionais</strong></u></span><span >”). Os Termos Adicionais são complementares e considerados parte integrante destes Termos para os efeitos dos respectivos Serviços. Os Termos Adicionais prevalecerão sobre estes Termos em caso de conflito somente com relação àquilo que forem específicos.</span></span></p>
                                    <p><span ><span ><strong>1.3.</strong></span><span ><span > </span></span><span ><strong>Comunicação com o Motorista Parceiro.</strong></span><span > O Motorista Parceiro autoriza a UAI MOBI a enviar notificações administrativas no Aplicativo (“</span><span ><em>push</em></span><span >”), por e-mail, SMS, em uma publicação em seu site ou por qualquer outro meio de comunicação disponível (“</span><span ><u><strong>Meios de Comunicação</strong></u></span><span >”) com conteúdo de natureza informativa e promocional relacionado aos Serviços. Autoriza também o fornecimento de dados para a comunicação entre motorista e passageiro através de ligação telefônica, chat do aplicativo ou outro meio que estiver disponível.</span></span></p>
                                    <p><span ><span ><strong>1.4.</strong></span><span ><span > </span></span><span ><strong>Alteração dos Termos.</strong></span><span > A UAI MOBI reserva o direito de realizar alterações e atualizações nos Termos, a qualquer momento, sem a necessidade de aviso prévio. Em caso de alterações dos Termos que restrinjam direitos dos Motoristas Parceiros, a UAI MOBI poderá comunicar tal alteração, ao seu critério, através dos Meios de Comunicação. Todavia, esta liberalidade não afasta a responsabilidade do Motorista Parceiro de verificar periodicamente os Termos. Caso o Motorista Parceiro não concorde com as alterações dos Termos, deverá cancelar sua conta, cessar toda e qualquer utilização dos Serviços e desinstalar o Aplicativo do seu aparelho. O fato do Motorista Parceiro continuar a acessar ou usar os Serviços após essa postagem representa seu consentimento em vincular-se aos Termos alterados.</span></span></p>
                                    <p><span ><span ><strong>2.</strong></span><span ><span > </span></span><span ><strong>DADOS DO MOTORISTA PARCEIRO E PRIVACIDADE</strong></span></span></p>
                                    <p><span ><span ><strong>2.1.</strong></span><span > A UAI MOBI POSSUI UMA POLÍTICA EXPRESSA SOBRE PRIVACIDADE. AS INFORMAÇÕES DE REGISTRO E OUTRAS INFORMAÇÕES SOBRE O MOTORISTA PARCEIRO ESTÃO SUJEITAS AO TRATAMENTO REFERIDO EM TAL POLÍTICA DE PRIVACIDADE. O MOTORISTA PARCEIRO ENTENDE E CONCORDA QUE O SEU USO E A PRESTAÇÃO DO SERVIÇO ENVOLVEM A COLETA E UTILIZAÇÃO DE INFORMAÇÕES E DADOS SOBRE O MOTORISTA PARCEIRO (CONFORME DEFINIDO NA POLÍTICA DE PRIVACIDADE APLICÁVEL), INCLUINDO A TRANSFERÊNCIA DESTAS INFORMAÇÕES E DADOS PARA OUTROS TERRITÓRIOS, PARA FINS DE ARMAZENAMENTO, PROCESSAMENTO E UTILIZAÇÃO PELA UAI MOBI, SUA CONTROLADORA E DEMAIS EMPRESAS DO MESMO GRUPO ECONÔMICO, PARA ENVIO ÀS AUTORIDADES COMPETENTES PARA FINS DE REALIZAÇÃO DE CADASTRAMENTO DOS MOTORISTAS PARCEIROS, PARA AS FINALIDADES DA PRESTAÇÃO DO SERVIÇO E DEMAIS PREVISTAS NA POLÍTICA DE PRIVACIDADE. FAVOR CONSULTAR A POLÍTICA DE PRIVACIDADE DA UAI MOBI NO SEGUINTE LINK: </span><span ><em>uaimobi.com.br</em></span><span >. A POLÍTICA DE PRIVACIDADE DA UAI MOBI CONSTITUI PARTE INTEGRANTE DOS TERMOS.</span></span></p>
                                    <p><span ><span ><strong>3. CADASTRO</strong></span></span></p>
                                    <p><span ><span ><strong>3.1.</strong></span><span ><span > </span></span><span >Para utilizar grande parte dos Serviços, o Motorista Parceiro deve registrar-se e manter apenas uma conta pessoal de Motorista Parceiro (“</span><span ><u><strong>Conta</strong></u></span><span >”). O Motorista Parceiro deve ter capacidade civil, possuir Carteira Nacional de Habilitação (“</span><span ><u>CNH</u></span><span >”) definitiva com permissão para exercício de atividade remunerada (“</span><span ><u>EAR</u></span><span >”) e deverá cumprir todos os requisitos legais correspondentes ao local de sua atuação para exercício da atividade de transporte remunerado individual de passageiros, para Motorista(s) Parceiro(s), para mototaxistas e para exercício da atividade de utilidade pública de transporte individual de passageiros (“</span><span ><u>Táxi</u></span><span >”) para Taxistas. O Motorista Parceiro que desejar utilizar o Serviço deverá obrigatoriamente preencher os campos de cadastro exigidos, inclusive relativos aos dados necessários para recebimento de valores em decorrência da sua atividade de transporte de passageiros, intermediado pela UAI MOBI, e responderá pela veracidade das informações declaradas, obrigando-se a manter informações válidas, atualizadas e corretas. Em caso de não confirmação de seus dados, o acesso do Motorista Parceiro ao Serviço poderá ser bloqueado, a exclusivo critério da UAI MOBI.</span></span></p>
                                    <p><span ><span ><strong>3.2. </strong></span><span >Após receber a documentação de cadastro, a UAI MOBI efetuará uma análise e poderá aceitar ou recusar a solicitação de cadastro do Motorista Parceiro. A UAI MOBI também poderá realizar a checagem de antecedentes criminais e quaisquer outras verificações que considerar oportunas ou que sejam exigidas pela legislação aplicável.</span></span></p>
                                    <p><span ><span ><strong>3.3.</strong></span><span > O perfil do Motorista Parceiro é exclusivo e intransferível. O Motorista Parceiro compromete-se, mediante aceitação dos Termos, a não compartilhar sua Conta com terceiros, sendo vedada a transferência de sua Conta, sob pena de cancelamento imediato da Conta do Motorista Parceiro, além de encaminhamento do caso às autoridades públicas para análise de eventuais penalidades criminais e civis aplicáveis.</span></span></p>
                                    <p><span ><span ><strong>3.3.1.</strong></span><span > A UAI MOBI se reserva o direito de solicitar documentos adicionais para confirmação de cadastros, bem como outros métodos de identificação e autenticação do Motorista Parceiro (como, por exemplo, reconhecimento facial), por ocasião do cadastro e enquanto o Motorista Parceiro utilizar os Serviços a qualquer tempo. Mesmo após a confirmação do cadastro, é possível o cancelamento da conta caso sejam verificadas incongruências no processo de verificação, a exclusivo critério da UAI MOBI.</span></span></p>
                                    <p><span ><span ><strong>3.3.2.</strong></span><span > As informações da Conta são de exclusiva responsabilidade de quem as inseriu. No caso de acarretarem danos ou prejuízos de qualquer espécie, as medidas cabíveis podem ser tomadas pela UAI MOBI a fim de resguardar seus interesses e a integridade de Passageiros, demais Motoristas Parceiros do Aplicativo e da própria UAI MOBI.</span></span></p>
                                    <p><span ><span ><strong>3.4.</strong></span><span > O Motorista Parceiro se compromete, sob as penas da lei, a utilizar sua Conta do Aplicativo apenas de maneira e para fins estritamente legais, legítimos e permitidos por estes Termos de Uso.</span></span></p>
                                    <p><span ><span ><strong>4. SERVIÇOS</strong></span></span></p>
                                    <p><span ><span ><strong>4.1.</strong></span><span ><span > </span></span><span ><strong>Serviços prestados pela UAI MOBI.</strong></span><span > Os Serviços consistem na intermediação de corridas e facilitação de pagamento (“</span><span ><u><strong>Intermediação</strong></u></span><span >”), mediante licenciamento e uso de software, em que a UAI MOBI é a licenciante do Aplicativo, de forma não-exclusiva (“</span><span ><u><strong>Licenciamento</strong></u></span><span >”) que possibilita ao Motorista Parceiro cadastrado localizar e contatar passageiros interessados em contratar serviço de transporte remunerado privado individual de passageiros ou transporte individual de passageiros de utilidade pública (“</span><span ><u><strong>Passageiro(s</strong></u></span><span ><strong>)</strong></span><span >” e “</span><span ><u><strong>Serviço de Transporte</strong></u></span><span >”, respectivamente).</span></span></p>
                                    <p><span ><span ><strong>4.1.1.</strong></span><span > O Motorista Parceiro concede à UAI MOBI as autorizações necessárias para a correta execução do serviço, nomeando-a sua representante para que, em seu nome, realize a cobrança de créditos, providencie o pagamento, compensação e reconhecimento de dívidas, dê e demande quitação, promova a liquidação de haveres e, de todo modo, atue no melhor interesse do representado.</span></span></p>
                                    <p><span ><span ><strong>4.2.</strong></span><span > A UAI MOBI concede ao Motorista Parceiro uma licença limitada, pessoal, não exclusiva, não transferível, não comercial e plenamente revogável, para utilizar o Aplicativo em seu </span><span ><em>smartphone</em></span><span >, </span><span ><em>tablet </em></span><span >ou computador em conformidade com as condições previstas nestes Termos de Uso.</span></span></p>
                                    <p><span ><span ><strong>4.3.</strong></span><span > A UAI MOBI não se responsabiliza por quaisquer danos sofridos pelo Motorista Parceiro em razão de cópia, transferência, distribuição ou qualquer outra forma de utilização de conteúdo protegido disponibilizado no Aplicativo.</span></span></p>
                                    <p><span ><span ><strong>4.4. </strong></span><span >A UAI MOBI reserva a si todos os direitos ao Aplicativo não expressamente concedidos aqui.</span></span></p>
                                    <p><span ><span ><strong>4.4.1.</strong></span><span > Por meio do Aplicativo, o Motorista Parceiro receberá chamadas de Passageiros, de acordo com a categoria selecionada pelo Passageiro, localização e destino, opcionais e outros critérios presentes ou futuros que venham a ser determinados pela UAI MOBI.</span></span></p>
                                    <p><span ><span ><strong>4.5. </strong></span><span >A UAI MOBI poderá prestar outros serviços ou oferecer produtos vinculados ou não ao Serviço, gratuitos ou onerosos, ao seu critério. Exceto se diferentemente definidos em Termos Adicionais, tais produtos, serviços e entregas estão sujeitos aos presentes Termos e definidos como Serviço para todos os efeitos legais deste Termo.</span></span></p>
                                    <p><span ><span ><strong>4.6. </strong></span><span >O Serviço não deve ser utilizado para qualquer finalidade que não as expressamente autorizadas por estes Termos.</span></span></p>
                                    <p><span ><span ><strong>4.7. Pagamento pelos Serviços. </strong></span><span >A UAI MOBI poderá cobrar por todo ou parte do Serviço, ao seu critério. Atualmente, o Licenciamento é feito a título gratuito, sendo que a Intermediação é prestada de maneira onerosa (“</span><span ><u>Remuneração pela Intermediação</u></span><span >”). Como remuneração pela Intermediação, a UAI MOBI cobrará do Motorista Parceiro um valor por cada corrida aceita, de acordo com a categoria do serviço, conforme segue:</span></span></p>
                                    <p><span ><span ><strong>Remuneração da UAI MOBI</strong></span></span></p>
                                    <p><span ><span >Para consultar o valor em seu município, acesse a Tabela de Remuneração: </span><span ><u><strong><a href="https://99app.com/legal/termos/motorista/tabela-de-remuneracao-taxistas/">uaimobi.com.br</a></strong></u></span><span > que será atualizada periodicamente.</span></span></p>
                                    <p> </p>
                                    <p><span ><span ><strong>4.7.1. </strong></span><span >A UAI MOBI disponibilizará ao Motorista Parceiro um recibo com o respectivo valor da Remuneração pela Intermediação. Sem prejuízo do disposto na respectiva Tabela de Remuneração, o Motorista Parceiro concorda que o valor a ser pago por ele a título de Remuneração pela Intermediação será o disponibilizado no recibo em caso de qualquer conflito ou inconsistência.</span></span></p>
                                    <p><span ><span ><strong>4.7.2. </strong></span><span >A cobrança dos valores devidos a título de Remuneração pela Intermediação pelo Motorista Parceiro à UAI MOBI, na forma descrita na Cláusula 4.5. Para corridas pagas pelo Passageiro por meio do Aplicativo, o pagamento da Remuneração pela Intermediação será realizado automaticamente por meio do Aplicativo e dos meios de pagamento relacionados. Para as corridas pagas por outro(s) meio(s)o, a cobrança dos valores devidos à UAI MOBI será realizada na próxima corrida cujo pagamento for realizado por intermédio do Aplicativo. Na hipótese de valores pendentes de forma reiterada, a UAI MOBI poderá ao seu critério (i) emitir contra o Motorista Parceiro a respectiva fatura / nota fiscal para o pagamento dos valores em aberto, à qual o Motorista Parceiro deverá proceder com o pagamento na data de vencimento indicada e/ou (ii) bloquear o cadastro do Motorista Parceiro até que os valores sejam pagos.</span></span></p>
                                    <p><span ><span ><strong>4.7.3. Cartão UAI MOBI. </strong></span><span >Ao se cadastrar no Aplicativo, se disponível, o Motorista Parceiro poderá optar por receber um cartão pré-pago oferecido pela UAI MOBI em parceria com empresas especializadas em serviços financeiros (“</span><span ><u>Cartão UAI MOBI</u></span><span >”), no qual serão depositados os valores referentes às corridas. O prazo para recebimento do Cartão UAI MOBI é determinado pelas empresas parceiras especializadas. Após o recebimento do Cartão UAI MOBI, o Motorista Parceiro deverá efetuar o seu desbloqueio no próprio Aplicativo ou qualquer outro meio disponível. Em caso de perda ou não recebimento do Cartão UAI MOBI, o Motorista Parceiro deverá entrar em contato imediatamente com a UAI MOBI, através dos canais aqui definidos, para que outro Cartão UAI MOBI seja providenciado.</span></span></p>
                                    <p><span ><span ><strong>4.7.3.1.</strong></span><span > O Motorista Parceiro se compromete a fornecer, em seu cadastro junto à UAI MOBI, todas as informações e documentos necessários à emissão, envio, desbloqueio e uso do Cartão UAI MOBI, caso o tenha, bem como a informar a UAI MOBI sobre quaisquer alterações nesses dados ou problemas no seu Cartão UAI MOBI ou na Conta a ele associada.</span></span></p>
                                    <p><span ><span ><strong>4.7.3.2. </strong></span><span >O Cartão UAI MOBI está sujeito às regras das empresas especializadas que o emitem, credenciam e gerenciam suas operações. Para que o Motorista Parceiro possa utilizá-lo, ele deve conhecer e aceitar todas as regras definidas pelas empresas do Arranjo de Pagamentos ao qual o Cartão UAI MOBI pertence.</span></span></p>
                                    <p><span ><span ><strong>4.7.3.3. </strong></span><span >A UAI MOBI não será responsabilizada pela falta ou atraso do repasse em virtude de:</span></span></p>
                                    <p><span ><span ><strong>a) </strong></span><span >Quaisquer problemas no Cartão UAI MOBI ou na conta de pagamento a ele associada que não tenham sido diretamente causados pela UAI MOBI;</span></span></p>
                                    <p><span ><span ><strong>b)</strong></span><span > Falhas no sistema de pagamento eletrônico disponibilizado por terceiros;</span></span></p>
                                    <p><span ><span ><strong>c)</strong></span><span > Caso fortuito ou força maior;</span></span></p>
                                    <p><span ><span ><strong>d)</strong></span><span > Quaisquer problemas decorrentes de ação ou omissão do Motorista Parceiro, incluindo, mas não somente, as hipóteses de divergência entre o valor estimado do trajeto e o valor cobrado ou de corrida sem registro de trajeto; e</span></span></p>
                                    <p><span ><span ><strong>e)</strong></span><span > Necessidade de análise do pagamento por prazo razoável por qualquer razão, incluindo indício de conduta fraudulenta.</span></span></p>
                                    <p><span ><span ><strong>4.7.4. Transferência Bancária. </strong></span><span >Alternativamente, nas localidades em que esta opção esteja disponível, o Motorista Parceiro poderá optar por receber diretamente em sua conta bancária os valores referentes ao Serviço de Transporte prestado ao Passageiro. Neste caso, a conta bancária deverá pertencer ao próprio Motorista Parceiro e os dados solicitados (Conta, Agência e Banco) deverão ser informados corretamente. Caso o Motorista Parceiro informe algum dado incorretamente, a UAI MOBI não se responsabiliza por eventuais atrasos na transferência e/ou perda dos valores transferidos. A transferência bancária poderá estar sujeita a cobrança de valores, conforme informado ao Motorista Parceiro no momento da ativação da opção ou do processo de transferência.</span></span></p>
                                    <p><span ><span ><strong>4.8. INEXISTÊNCIA DE RELAÇÃO DE TRABALHO, VÍNCULO DE EMPREGO E OUTROS.</strong></span><span > NÃO SE ESTABELECE ENTRE O MOTORISTA PARCEIRO E A UAI MOBI QUALQUER VÍNCULO DE NATUREZA SOCIETÁRIA, EMPREGATÍCIA E/OU ECONÔMICA, SENDO CERTO QUE O MOTORISTA PARCEIRO É LIVRE PARA ACEITAR OU RECUSAR CORRIDAS A PARTIR DO APLICATIVO, BEM COMO PARA CESSAR A SUA UTILIZAÇÃO A QUALQUER MOMENTO, AO SEU LIVRE E EXCLUSIVO CRITÉRIO. A UAI MOBI NÃO POSSUI UMA FROTA DE VEÍCULOS, PRESTANDO EXCLUSIVAMENTE OS SERVIÇOS DE LICENCIAMENTO E INTERMEDIAÇÃO VOLTADOS À FACILITAÇÃO DA CONTRATAÇÃO DE SERVIÇO DE TRANSPORTE PERANTE UM MOTORISTA PARCEIRO CADASTRADO NO APLICATIVO.</span></span></p>
                                    <p><span ><span ><strong>4.9. RESPONSABILIDADES PELOS SERVIÇOS.</strong></span><span > A UAI MOBI assume a responsabilidade por todos os problemas aos quais der causa injustificada. A UAI MOBI deverá ainda manter o bom funcionamento do Aplicativo na maior medida possível e disponibilizar canais de atendimento para ajudar a solucionar dúvidas e problemas dos usuários do Aplicativo, observado o disposto na Cláusula 4.9, abaixo.</span></span></p>
                                    <p><span ><span ><strong>4.10.</strong></span><span ><span > </span></span><span ><strong>RESPONSABILIDADE PELOS SERVIÇOS DE TRANSPORTE. </strong></span><span >A CONTRATAÇÃO DOS SERVIÇOS DE TRANSPORTE É FEITA DIRETAMENTE ENTRE OS PASSAGEIROS E OS MOTORISTAS PARCEIROS. A UAI MOBI NÃO SE RESPONSABILIZA POR QUAISQUER PERDAS, PREJUÍZOS OU DANOS DE QUALQUER NATUREZA QUE SEJAM DECORRENTES DA RELAÇÃO ENTRE PASSAGEIRO E MOTORISTA PARCEIRO. O MOTORISTA PARCEIRO ENTENDE E CONCORDA QUE A UAI MOBI NÃO SERÁ RESPONSÁVEL POR QUAISQUER DANOS OU PREJUÍZOS QUE VENHAM A SER CAUSADOS POR UM PASSAGEIRO AO RESPECTIVO MOTORISTA PARCEIRO.</span></span></p>
                                    <p><span ><span ><strong>4.10.1. Danos e prejuízos causados pelos Motoristas Parceiros. </strong></span><span >O Motorista Parceiro será responsável por quaisquer danos ou prejuízos que causar ao(s) Passageiro(s) e concorda em indenizar e manter a UAI MOBI indene em relação a quaisquer demandas, perdas, prejuízos ou danos direta ou indiretamente relacionados a atos ou fatos causados pelo Motorista Parceiro. O Motorista Parceiro é o único e exclusivo responsável por todos e quaisquer problemas relativos ao Serviço de Transporte, bem como por quaisquer condutas indevidas ou ilegais que pratique.</span></span></p>
                                    <p><span ><span ><strong>4.11.</strong></span><span ><span > </span></span><span ><strong>Acesso à Rede e Equipamentos. </strong></span><span >O Motorista Parceiro entende e concorda que a utilização do Serviço demanda a aquisição de dispositivos de telefonia móvel e a contratação de serviços de telecomunicação compatíveis com o uso do Aplicativo, e que a utilização do Serviço poderá gerar cobranças por parte de tais prestadores de serviço de telecomunicação para conexão com a Internet, por exemplo. O Motorista Parceiro é o responsável exclusivo por contratar e arcar com todo e qualquer custo e ônus relacionados à aquisição de seu dispositivo de telefonia móvel e a contratação de seu serviço de telecomunicação.</span></span></p>
                                    <p><span ><span ><strong>4.11.1. </strong></span><span >A UAI MOBI não é responsável pela disponibilidade, qualidade e manutenção de tais serviços de telecomunicação e o Usuário entende que as condições do serviço de telecomunicações poderão afetar a experiência do Serviço. A UAI MOBI não será responsabilizada por qualquer problema relacionado ao Serviço decorrente direta ou indiretamente de inconsistências ou falhas nos dispositivos de telefonia móvel e/ou nos serviços de telecomunicação. Além disso, a UAI MOBI não será responsabilizada por eventuais erros e inconsistências das informações oriundas de terceiros relativas ao geoposicionamento via sistema GPS e aos mapas integrados ao Aplicativo.</span></span></p>
                                    <p><span ><span ><strong>4.12. Modificação ou Encerramento do Serviço</strong></span><span >.</span><span ><strong> </strong></span><span >A UAI MOBI se reserva o direito de, a qualquer tempo, modificar ou descontinuar, temporariamente ou permanentemente, o Serviço ou parte dele, com ou sem notificação. O Motorista Parceiro concorda que a UAI MOBI não será responsabilizada, nem terá qualquer obrigação adicional, implícita ou explícita, para com o Motorista Parceiro ou terceiros em razão de qualquer modificação, suspensão ou desativação do Serviço.</span></span></p>
                                    <p><span ><span ><strong>5. SERVIÇO DE TRANSPORTE</strong></span></span></p>
                                    <p><span ><span ><strong>5.1. Obrigação do Motorista. </strong></span><span >O Motorista Parceiro deve respeitar todas as regras destes Termos de Uso e de toda legislação aplicável. O descumprimento dos Termos de Uso ou da legislação aplicável pelo Motorista Parceiro poderá resultar, a livre e exclusivo critério da UAI MOBI, impedimento do seu acesso ao Aplicativo. Mediante aceitação dos Termos de Uso, o Motorista Parceiro compromete-se a:</span></span></p>
                                    <p><span ><span ><strong>a)</strong></span><span > Pagar a Remuneração pela Intermediação à UAI MOBI;</span></span></p>
                                    <p><span ><span ><strong>b) </strong></span><span >Agir perante a UAI MOBI e aos Passageiros com boa-fé, diligência, profissionalismo e respeito;</span></span></p>
                                    <p><span ><span ><strong>c) </strong></span><span >Obedecer a todas as exigências legais e regulatórias referentes aos serviços de transporte remunerado privado individual de passageiros ou do transporte de passageiros de utilidade pública, incluindo as leis, regulamentos e demais normas de trânsito aplicáveis em âmbito federal, estadual e municipal, tanto quanto ao Motorista Parceiro como em relação ao veículo utilizado para o Serviço de Transporte;</span></span></p>
                                    <p><span ><span ><strong>d)</strong></span><span > Não discriminar ou selecionar, por nenhum motivo, os Passageiros;</span></span></p>
                                    <p><span ><span ><strong>e)</strong></span><span > Responsabilizar-se integralmente pela prestação do serviço de transporte de pessoas; e</span></span></p>
                                    <p><span ><span ><strong>f)</strong></span><span > Responsabilizar-se pelo uso de sua própria máquina de cartão de crédito e/ou débito e pelos seus respectivos recebimentos.</span></span></p>
                                    <p><span ><span ><strong>5.2. Pagamento pela Prestação do Serviço de Transporte.</strong></span><span ><span > </span></span><span >O pagamento da corrida é feito diretamente pelo(s) Passageiro(s) ao Motorista Parceiro, por uma das modalidades de pagamento escolhidas pelo Motorista Parceiro, sendo cobrado do Motorista Parceiro o valor da Remuneração pela Intermediação, conforme previsto na Cláusula 4.5., acima. O Motorista Parceiro pode optar por receber pagamentos em uma ou mais das seguintes modalidades: (i) dinheiro; (ii) em sua própria máquina de cartão de débito ou crédito; (iii) em cartão por meio do próprio Aplicativo e/ou (iv) outros meios de pagamento disponíveis no Aplicativo. </span></span></p>
                                    <p><span ><span ><strong>5.2.1.</strong></span><span ><span > </span></span><span ><strong>Cálculo do Preço do Serviço de Transporte prestado pelo Taxista.</strong></span><span > O valor a ser pago pelo Passageiro ao Taxista é calculado com base na legislação aplicável na região de realização da corrida, quando houver.</span></span></p>
                                    <p><span ><span ><strong>5.2.1.1. Modo Desconto. </strong></span><span >A UAI MOBI poderá disponibilizar ao Passageiro, por meio do Aplicativo, um desconto variável no valor das corridas (“</span><span ><u>Modo Desconto</u></span><span >”). O Taxista poderá aceitar ou não as corridas do Modo Desconto a ele oferecidas.</span></span></p>
                                    <p><span ><span ><strong>5.2.2. Cálculo do Preço do Serviço de Transporte prestado pelo Motorista Parceiro. </strong></span><span >No momento em que o Passageiro tenha inserido o destino e solicitado o Serviço de Transporte por meio do Aplicativo, receberá o valor do preço pelo trajeto correspondente (“</span><span ><u>Preço</u></span><span >”). O Preço será composto por: (i) valor base/mínimo; acrescido de (ii) parcela variável baseada na distância e quantidade de tempo estimado para o trajeto. Além disso, em certas situações em que a demanda por viagens aumentar muito, é possível a incidência de ajuste adicional dinâmico (“</span><span ><u><strong>Ajuste Dinâmico</strong></u></span><span >”). O intuito do Ajuste Dinâmico é equilibrar oferta e demanda ao incentivar que mais </span><span >Motorista(s) Parceiro(s) se conectem ao Aplicativo e aceitem solicitações de corrida</span><span >. O Ajuste Dinâmico será parte integrante do Preço.</span></span></p>
                                    <p><span ><span ><strong>5.2.2.1. Variação do Preço após aceite da corrida. </strong></span><span >O Preço pode aumentar se o Passageiro alterar o destino durante a viagem, se fizer paradas extras ao longo do trajeto, se for uma viagem longa que exija preço de retorno, se houver cobrança de pedágios ou se a viagem levar muito mais tempo do que o esperado, em decorrência de atos do Passageiro, e quaisquer eventos que não estejam sob o controle do Motorista Parceiro e/ou da UAI MOBI, incluindo caso fortuito e força maior (e.g. realização de eventos na cidade que alterem o tráfego, ocorrência de chuvas e acidentes naturais, etc.) (“</span><span ><u><strong>Hipóteses de Variação de Preço</strong></u></span><span >”). Nas Hipóteses de Variação do Preço, ao final da corrida, será cobrado adicional ao valor do Preço (“</span><span ><u><strong>Adicional ao Preço</strong></u></span><span >”).</span></span></p>
                                    <p><span > <span ><strong>5.2.2.2.</strong></span><span ><span > </span></span><span >A UAI MOBI se reserva o direito de estabelecer, remover e/ou revisar a forma de Cálculo do Preço do Serviço de Transporte prestado pelo Motorista Parceiro por intermédio do uso dos Serviços a qualquer momento, a critério exclusivo da UAI MOBI. Ademais, o Preço aplicável em certas áreas geográficas poderão incluir cobranças, tarifas, taxas, impostos e/ou contribuições governamentais, inclusive, tarifas de pedágios ou cobranças de aeroportos, conforme a rota tomada pelo Motorista Parceiro, o local de origem e/ou destino da viagem.</span></span></p>
                                    <p><span ><span ><strong>5.3. Códigos Promocionais e Ações de Recompensa. </strong></span><span >A UAI MOBI poderá, a seu exclusivo critério, criar códigos promocionais que poderão ser resgatados para crédito na Conta dos Passageiros (“</span><span ><u><strong>Códigos Promocionais</strong></u></span><span >”) ou ações de recompensa para Motoristas Parceiros (“</span><span ><u>Ações de Recompensa</u></span><span >”)</span></span></p>
                                    <p><span ><span ><strong>5.3.1.</strong></span><span > É vedado ao Motorista Parceiro utilizar Códigos Promocionais ou Ações de Recompensa em conluio com qualquer Passageiro ou com terceiros para obter vantagens indevidas ou fraudar o Serviço.</span></span></p>
                                    <p><span ><span ><strong>5.4. Gorjeta.</strong></span><span > A UAI MOBI poderá oferecer ao Passageiro uma funcionalidade por meio da qual seja possível ofertar ao Motorista Parceiro uma gorjeta, como forma de incentivo a um serviço rápido, atencioso e eficiente. Essa gorjeta não será obrigatoriamente paga pelo Passageiro.</span></span></p>
                                    <p><span ><span ><strong>5.5. Taxa de Cancelamento. </strong></span><span >O Passageiro poderá optar por cancelar sua solicitação dos Serviços de Transporte prestados pelo Motorista Parceiro a qualquer momento antes do início da corrida, caso em que poderá incidir uma taxa de cancelamento (“</span><span ><u><strong>Taxa de Cancelamento</strong></u></span><span >”). A Taxa de Cancelamento será destinada ao Motorista Parceiro como forma de ressarcimento pelo seu deslocamento e tempo de espera.</span></span></p>
                                    <p><span ><span ><strong>6. MANUTENÇÃO DO PADRÃO DE QUALIDADE UAI MOBI</strong></span></span></p>
                                    <p><span ><span ><strong>6.1. </strong></span><span >O Motorista Parceiro aceita que será avaliado pelos Passageiros e pela UAI MOBI com base em critérios como a qualidade do serviço, a limpeza do veículo e as taxas de aceite e de cancelamento de corridas. O Motorista Parceiro que for reiteradamente mal avaliado poderá ter a sua licença de uso do Aplicativo cancelada. Sem prejuízo de outras disposições constantes neste instrumento, o Motorista Parceiro também poderá ser ter sua Conta cancelada (resultando em impedimento de acesso ao Aplicativo) em casos como pendências cadastrais, relatos de condutas inapropriadas, a exclusivo critério da UAI MOBI.</span></span></p>
                                    <p><span ><span ><strong>6.2.</strong></span><span > O Motorista Parceiro reconhece e aceita que a UAI MOBI não está obrigada a filtrar, censurar ou controlar, nem se responsabiliza pelas avaliações realizadas pelos Passageiros, que são os únicos e exclusivos responsáveis por seu conteúdo. Tais avaliações não expressam a opinião ou o julgamento da UAI MOBI sobre os Motoristas Parceiros.</span></span></p>
                                    <p><span ><span ><strong>6.3. </strong></span><span >O Motorista Parceiro aceita que a UAI MOBI manterá registros internos acerca da prestação de Serviços de Transporte, tais como a taxa de aceitação e cancelamento de corridas, podendo utilizar esses dados para realizar sua própria avaliação sobre o Motorista Parceiro.</span></span></p>
                                    <p><span ><span ><strong>6.4. </strong></span><span >Periodicamente, o Motorista Parceiro será informado sobre alterações em sua avaliação, que consistirá em uma nota de 1 (um) a 5 (cinco), que leva em consideração a avaliação e comentários dos Passageiros e da UAI MOBI, conforme os critérios estabelecidos na Cláusula 6.1, bem como outros critérios pertinentes.</span></span></p>
                                    <p><span ><span ><strong>6.5. </strong></span><span >O Motorista Parceiro reconhece e aceita que a UAI MOBI poderá: (i) suspender por tempo indeterminado o Licenciamento (e, consequentemente, a Conta do Motorista Parceiro), dentre outros motivos elencados nas Cláusula 8 a seguir; ou (ii) exigir a realização de curso de reciclagem, caso o Motorista Parceiro apresente avaliações semanais reiteradamente ruins, a exclusivo critério da UAI MOBI.</span></span></p>
                                    <p><span ><span ><strong>7. DA SUSPEITA DE FRAUDE</strong></span></span></p>
                                    <p><span ><span ><strong>7.1.</strong></span><span > A UAI MOBI não se responsabiliza por quaisquer condutas fraudulentas por parte do Motorista Parceiro, podendo inclusive impedir, em razão delas, seu acesso ao Aplicativo, bem como agir judicial ou extrajudicialmente contra o envolvido.</span></span></p>
                                    <p><span ><span ><strong>7.2. </strong></span><span >A UAI MOBI analisa caso a caso as suspeitas de fraude e poderá reter o pagamento de valores ao Motorista Parceiro em virtude de corridas supostamente fraudulentas, de forma a evitar o risco de dano à UAI MOBI.</span></span></p>
                                    <p><span ><span ><strong>7.3.</strong></span><span > A UAI MOBI se reserva ao direito de descontar, em pagamentos subsequentes eventualmente devidos ao Motorista Parceiro, os valores anteriormente pagos sobre os quais tenha sido constatada fraude.</span></span></p>
                                    <p><span ><span ><strong>8. SUSPENSÃO E CANCELAMENTO DE SEU ACESSO AO APLICATIVO</strong></span></span></p>
                                    <p><span ><span ><strong>8.1. </strong></span><span >O Motorista Parceiro concorda que a UAI MOBI, à sua livre discrição, poderá suspender ou cancelar sua utilização do Serviço, incluindo, mas não se limitando: (i) por descumprimentos e/ou violação destes Termos; (ii) pelo resultado de sua avaliação pelos Passageiros e pela análise de sua taxa de cancelamento e outros critérios, nos termos da Cláusula 6, acima; (iii) em função de ordem judicial ou requisição legal de autoridade pública competente; (iv) por requisição do próprio Motorista Parceiro; (v) por desativação ou modificação do Serviço (ou de qualquer de suas partes); (vi) por caso fortuito, força maior e/ou questões de segurança; (vii) por inatividade da conta por um longo período de tempo; (viii) pela suposta prática de qualquer infração de trânsito, atividade fraudulenta ou ilegal por parte do Motorista Parceiro, a critério da UAI MOBI; (ix) pelo uso inadequado ou abusivo do Aplicativo, incluindo a utilização por terceiros ou transferência de sua Conta, a realização de corrida com veículo distinto do cadastrado no Aplicativo, utilização de quaisquer aplicativos ou programas que visem a alterar a informação da localização geográfica do Motorista Parceiro para manipular o Aplicativo, e outras hipóteses de uso indevido ou abusivo do Aplicativo, a critério da UAI MOBI; e/ou (x) por inadimplemento por parte do Motorista Parceiro de quaisquer obrigações, valores, pagamentos devidos em razão do Serviço, quando aplicável.</span></span></p>
                                    <p><span ><span ><strong>8.2.</strong></span><span > O MOTORISTA PARCEIRO CONCORDA QUE O TÉRMINO DE SEU ACESSO AO SERVIÇO, POR QUALQUER RAZÃO CONSTANTE DESTES TERMOS, PODE OCORRER SEM UMA NOTIFICAÇÃO PRÉVIA E TODAS AS INFORMAÇÕES E DADOS CONSTANTES PODERÃO SER PERMANENTEMENTE APAGADOS.</span></span></p>
                                    <p><span ><span ><strong>8.3. </strong></span><span >A UAI MOBI se reserva o direito de agir judicialmente e extrajudicialmente em casos de danos sofridos pela UAI MOBI ou por terceiros, inclusive poderá entrar em contato com as autoridades e dar início à instrução de processos criminais, civis e administrativos nos casos previstos pela lei, quando julgar necessário.</span></span></p>
                                    <p><span ><span ><strong>8.4.</strong></span><span > A suspensão ou resilição destes Termos de Uso pela UAI MOBI não isenta o Motorista Parceiro do pagamento de quaisquer valores eventualmente devidos à UAI MOBI, que seguirão sendo passíveis de cobrança e de compensação.</span></span></p>
                                    <p><span ><span ><strong>8.5.</strong></span><span > O Motorista Parceiro não fará jus a qualquer indenização ou compensação, seja pela suspensão ou resilição destes Termos de Uso pela UAI MOBI.</span></span></p>
                                    <p><span ><span ><strong>9. PROPRIEDADE INTELECTUAL</strong></span></span></p>
                                    <p><span ><span ><strong>9.1.</strong></span><span > As marcas, nomes, logotipos, nomes de domínio e demais sinais distintivos, bem como todo e qualquer conteúdo, desenho, arte ou </span><span ><em>index</em></span><span > publicado no Aplicativo e o próprio Serviço, são de propriedade exclusiva da UAI MOBI.</span></span></p>
                                    <p><span ><span ><strong>9.2. </strong></span><span >São vedados quaisquer atos ou contribuições tendentes à descompilação, engenharia reversa, modificação das características, ampliação, alteração, mesclagem ou incorporação em quaisquer outros programas ou sistemas do Aplicativo ou Serviço. Enfim, toda e qualquer forma de reprodução do Aplicativo ou Serviço, total ou parcial, permanente, temporária ou provisória, de forma gratuita ou onerosa, sob quaisquer modalidades, formas ou títulos é expressamente vedada.</span></span></p>
                                    <p><span ><span ><strong>10. DISPOSIÇÕES FINAIS</strong></span></span></p>
                                    <p><span ><span ><strong>10.1. </strong></span><span >Estes Termos constituem o acordo integral entre o Motorista Parceiro e a UAI MOBI e regerão a utilização do Serviço pelo Motorista Parceiro, substituindo qualquer acordo anterior.</span></span></p>
                                    <p><span ><span ><strong>10.2.</strong></span><span ><span > </span></span><span ><strong>Legislação Aplicável. </strong></span><span >Estes Termos e o relacionamento entre o Motorista Parceiro e a UAI MOBI serão regidos pelas leis da República Federativa do Brasil. Fica eleito o foro da Comarca de Barão de Cocais como sendo o único competente para dirimir quaisquer litígios e/ou demandas que venham a envolver as partes em relação ao uso e acesso de seu site e Aplicativo. O Motorista Parceiro e a UAI MOBI concordam em submeter-se à competência única e exclusiva dos tribunais localizados no Brasil.</span></span></p>
                                    <p><span ><span ><strong>10.3.</strong></span><span ><span > </span></span><span ><strong>Notificações. </strong></span><span >Todas as notificações, intimações, ofícios ou qualquer outra forma oficial de cientificação da UAI MOBI deverão ocorrer em seu endereço de sede.</span></span></p>
                                    <p><span ><span ><strong>10.4. Renúncia de Direitos. </strong></span><span >Caso a UAI MOBI deixe de exercer ou executar qualquer direito ou dispositivo destes Termos, isto não será caracterizado como uma renúncia a tal direito ou dispositivo nem constituirá novação. Se qualquer dispositivo dos Termos for considerado inválido por um tribunal competente, as partes todavia concordam que o restante dos Termos deverá ser interpretado de tal forma a refletir a intenção original, e os outros dispositivos dos Termos permanecerão em pleno vigor e efeito.</span></span></p>
                                    <p><span ><span ><strong>10.5. Cessão de Direitos.</strong></span><span > O Motorista Parceiro não poderá ceder tampouco transferir estes Termos, total ou parcialmente, sem prévia aprovação por escrito da UAI MOBI. O Motorista Parceiro concede sua aprovação para que a UAI MOBI ceda e transfira estes Termos total ou parcialmente, inclusive: (i) para sua controladora, subsidiária ou afiliada; (ii) um adquirente das participações acionárias, negócios ou bens da UAI MOBI; ou (iii) para um sucessor em razão de qualquer operação societária. Não existe </span><span ><em>joint-venture</em></span><span >, sociedade, emprego ou relação de representação entre o Passageiro, a UAI MOBI ou quaisquer Motoristas Parceiros como resultado do contrato entre você e a UAI MOBI ou pelo uso dos Serviços.</span></span></p>
                                    <p><span ><span ><strong>10.6.</strong></span><span ><span > </span></span><span ><strong>Indivisibilidade. </strong></span><span >Caso qualquer disposição destes Termos seja tida como ilegal, inválida ou inexequível total ou parcialmente, por qualquer legislação, essa disposição ou parte dela será, naquela medida, considerada como não existente para os efeitos destes Termos, mas a legalidade, validade e exequibilidade das demais disposições contidas nestes Termos não serão afetadas. Nesse caso, as partes substituirão a disposição ilegal, inválida ou inexequível, ou parte dela, por outra que seja legal, válida e exequível e que, na máxima medida possível, tenha efeito similar à disposição tida como ilegal, inválida ou inexequível para fins de conteúdo e finalidade dos presentes Termos. Estes Termos constituem a totalidade do acordo e entendimento das partes sobre este assunto e substituem e prevalecem sobre todos os entendimentos e compromissos anteriores sobre este assunto. Nestes Termos, as palavras “inclusive” e “inclui” significam “incluindo, sem limitação”.</span></span></p>
                                    <p> </p>
                                    <p> </p>
                                    <p> </p>
                                </div>
                            </div>
                            
                             <a className='buttonJoin' 
                             href='https://play.google.com/store/apps/details?id=br.com.uaimobi.taxi.drivermachine&hl=pt_BR&gl=US'
                             target='self'>
                        <button className='buttons'>
                                Cadastrar
                            </button>
                        </a>
                        </div>
                    </Section>
                </div>
            </div>
            <Footer />
        </Fragment>

    );
};



export default about;


