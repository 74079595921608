import React, { Fragment } from 'react';

import Section from '../HOC/Section';
import Header from '../components/UI/Header/Header'
import Footer from '../components/UI/Footer/Footer'
const about = () => {
    return (
        <Fragment>
            <Header inHome={false}/>
            <div>
                <div>
                    <Section id='pageAbout'>
                        <div className='container pt-2 pb-5'>
                            <div className='section-header pt-5 pb-5 text-center'>
                                <h3 className='title-page'>
                                    <span><br /><br /><br />
                                        Sobre 
                                    </span> o UaiMobi
                                </h3>
                            </div>
                            <div className='row'>
                                <div className=' text-center'>
                                    <div className='about-description-page'>
                                        <center>
                                        <p>
                                        Olá! A empresa, Uai Mobi – serviço de transporte por aplicativo,
                                        nasceu em outubro de 2019, na cidade de Barão de Cocais.
                                        O objetivo com a criação da empresa, para mim e minha família,
                                        era ganhar uma renda extra, no entanto, fomos surpreendidos com
                                        a mudança de foco que a empresa ganhou.
                                        <br /><br />

                                        Inicialmente o rendimento era negativo, gerando apenas gastos
                                        e bastante transtornos, estes que nos fizeram pensar em desistir,
                                        pensamos até em vender o negócio. Porém, lembramos de muitos pais
                                        de família desempregados, e das pessoas buscaram uma renda extra
                                        para realizar os seus sonhos, no Uai Mobi a resposta para
                                        solucionar suas questões financeiras.
                                        <br /><br />

                                        Diante desse cenário ao qual estamos vivendo devido a essa
                                        pandemia, o Uai Mobi tem sido o recurso, e para muitos o único,
                                         para gerar renda para suas famílias. Muitos perderam seus
                                         empregos e muitos afastados de suas funções encontraram no
                                         Uai Mobi uma forma de sentirem úteis e trazerem recursos para
                                          sua casa.
                                        <br /><br />

                                        Então, apesar de não atender as nossas expectativas iniciais,
                                        nos apegamos em todo esse contexto que a empresa estava inserida
                                         e mudamos o foco, e hoje o nosso objetivo é permanecer e crescer
                                          para que famílias encontrem nela o recurso para gerar a renda
                                          que necessitam.
                                        <br /><br />

                                        E para que isso aconteça, todos podem fazer parte! Qualquer
                                        ajuda é bem vinda, desde serviços fotográficos para divulgação
                                         da nossa empresa, tornando o aplicativo mais conhecido, com
                                          mais usuários e assim aumentando as corridas e a renda dos
                                           nossos motoristas.
                                        <br /><br />

                                        Que Deus os abençoe!
                </p>
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Section>
                </div>
            </div>
            <Footer inHome={false} />
        </Fragment>

    );
};



export default about;


