import React, { Fragment } from 'react';

import Section from '../HOC/Section';
import Header from '../components/UI/Header/Header'
import Footer from '../components/UI/Footer/Footer'
const driver = () => {
    return (
        <Fragment>
            <Header inHome={false} />
            <div>
                <div>
                    <Section id='pageDriver'>
                        <div className='container pt-2 pb-5'>
                            <div className='section-header pt-5 pb-5 text-center'>
                                <h3 className='title-page'>
                                    <span><br /><br /><br />
                                        Motoristas
                                    </span> UaiMobi
                                </h3>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 driver-description-page' >
                                    <center>
                                        <p>
                                            Em breve mais informações
                                        <br /><br />
                                            <div className='service-icon mr-10'>
                                                <i className="fas fa-taxi " />
                                            </div>

                                        </p>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </Section>
                </div>
            </div>
            <Footer inHome={false} />
        </Fragment>

    );
};



export default driver;


