import React from 'react';
import Section from '../../../HOC/Section';

const Join = () => {
  return (
    <Section id='join'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Download </span>
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
            Você pode fazer o download do app de motorista ou passageiro,
            clique nos links abaixo
            <br /><br />
            Caso tenha alguma dúvida, entre em contato conosco através do "contate-nos"
            que esta nesta página no telefone (31) 99757-7475.

          </h6>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-md-6 col-md-offset-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className="fas fa-taxi " />
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Motorista</h5>
                  <p className='service-description'>
                    Clique e faça o download.
                  </p>
                  <a target='self' href='https://play.google.com/store/apps/details?id=br.com.uaimobi.taxi.drivermachine&hl=pt_BR&gl=US'>
                    <button className='buttons'><i className='fab fa-google-play mr-4' /> Download</button>
                  </a>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-md-offset-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className="fas fa-grin-beam" />
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Passageiro</h5>
                  <p className='service-description'>
                    Clique e faça o download.
                  </p>
                  <a target='self' href='https://play.google.com/store/apps/details?id=br.com.uaimobi.passenger.drivermachine&hl=pt_BR&gl=US'>
                    <button className='buttons'><i className='fab fa-google-play mr-4' /> Download</button>
                  </a>
                  <a target='self' href='https://apps.apple.com/br/app/seu-motorista/id1317089898?l=pt&ls=1'>
                    <button className='buttons'><i className='fab fa-apple mr-4' /> Download</button>
                  </a>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </Section>
  );
};

export default Join;
