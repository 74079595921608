import React, { Fragment } from 'react';

import Section from '../HOC/Section';
import Header from '../components/UI/Header/Header'
import Footer from '../components/UI/Footer/Footer'
const about = () => {
    return (
        <Fragment>
            <Header inHome={false} />
            <div>
                <div>
                    <Section id='pageAbout'>
                        <div className='container pt-2 pb-5'>
                            <div className='section-header pt-5 pb-5 text-center'>
                                <h3 className='title-page'>
                                    <span><br /><br /><br />
                                        Termos de uso para
                                    </span> Cadastro de Passageiro
                                </h3>
                            </div>
                            <div className='row'>
                                <div className='about-description'>
                                <p><span ><strong><span >1. INTRODUÇÃO E DISPOSIÇÕES GERAIS</span></strong></span></p>
                                <p><span ><strong><span >1.1.</span></strong></span><span ><span > </span></span><span ><strong>Bem-vindo à UAI MOBI! </strong></span><span >Estes são os Termos de Uso (“</span><span ><strong>Termos</strong></span><span >”) que regem o acesso e uso, como pessoa física, dentro do Brasil, dos serviços prestados através de aplicação tecnológica (“</span><span ><strong>Aplicativo</strong></span><span >”), sítios da Internet e conteúdos relacionados (“</span><span ><strong>Serviço(s)</strong></span><span >”), disponibilizados pela UAI MOBI Tecnologia. (“</span><span ><strong>UAI MOBI</strong></span><span >”), ou qualquer de suas afiliadas, a toda e qualquer pessoa que utilize os Serviços na qualidade de passageiro, a seguir denominado simplesmente “</span><span ><strong>Usuário(s)</strong></span><span >”.</span></p>
                                <p><span ><strong><span >1.1.1.</span></strong></span><span ><span > </span></span><span >POR FAVOR, LEIA COM ATENÇÃO ESTES TERMOS ANTES DE ACESSAR OU USAR OS SERVIÇOS.</span></p>
                                <p><span ><strong><span >1.2.</span></strong></span><span ><span > </span></span><span ><strong>ACEITAÇÃO DOS TERMOS E CONDIÇÕES DE USO.</strong></span><span > AO SE CADASTRAR E UTILIZAR CONTINUAMENTE OS SERVIÇOS, O USUÁRIO ESTARÁ DECLARANDO TER LIDO, ENTENDIDO E ACEITO OS TERMOS. CASO, A QUALQUER TEMPO, O USUÁRIO NÃO CONCORDE COM OS TERMOS, DEVERÁ CESSAR IMEDIATAMENTE A UTILIZAÇÃO DO APLICATIVO E DESINSTALÁ-LO DE SEU APARELHO.</span></p>
                                <p><span ><strong><span >1.2.1.</span></strong></span><span ><span > </span></span><span >Termos Adicionais poderão se aplicar a determinados Serviços, tais como condições para eventos, regulamentos, atividades ou promoções em particular, e esses Termos Adicionais serão divulgados em relação aos respectivos Serviços (“</span><span ><strong>Termos Adicionais</strong></span><span >”). Os Termos Adicionais são complementares e considerados parte integrante destes Termos para os efeitos dos respectivos Serviços. Os Termos Adicionais prevalecerão sobre estes Termos em caso de conflito somente com relação àquilo que forem específicos.</span></p>
                                <p> <span ><strong><span >1.3.</span></strong></span><span ><span > </span></span><span ><strong>Comunicação com o Usuário.</strong></span><span > O Usuário autoriza a UAI MOBI a enviar notificações administrativas no Aplicativo (“</span><span ><em>push</em></span><span >”), por e-mail, SMS, em uma publicação em seu site ou por qualquer outro meio de comunicação disponível (“</span><span ><strong>Meios de Comunicação</strong></span><span >”) com conteúdo de natureza informativa e promocional relacionados aos Serviços. Autoriza também o fornecimento de dados para a comunicação entre motorista e passageiro através de ligação telefônica, chat do aplicativo ou outro meio que estiver disponível.</span></p>
                                <p><span ><strong><span >1.4.</span></strong></span><span ><span > </span></span><span ><strong>Alteração dos Termos.</strong></span><span > A UAI MOBI se resguarda ao direito de realizar alterações e atualizações nos Termos, a qualquer momento, sem a necessidade de aviso prévio. Em caso de alterações dos Termos que restrinjam direitos dos Usuários, a UAI MOBI comunicará tal alteração, ao seu critério, através dos Meios de Comunicação. Todavia, esta liberalidade não afasta a responsabilidade do Usuário de verificar periodicamente os Termos. Caso o Usuário não concorde com as alterações dos Termos, deverá cancelar sua conta, cessar toda e qualquer utilização dos Serviços e desinstalar o Aplicativo do seu aparelho. O fato do Usuário continuar a acessar ou usar os Serviços após essa postagem representa seu consentimento em vincular-se aos Termos alterados.</span></p>
                                <p><span ><span ><span >2. </span></span><span ><strong>DADOS DO USUÁRIO E PRIVACIDADE</strong></span></span></p>
                                <p><span ><strong><span >2.1.</span></strong></span><span ><span > </span></span><span >A UAI MOBI POSSUI UMA POLÍTICA EXPRESSA SOBRE PRIVACIDADE. AS INFORMAÇÕES DE REGISTRO E OUTRAS INFORMAÇÕES SOBRE O USUÁRIO ESTÃO SUJEITAS AO TRATAMENTO REFERIDO EM TAL POLÍTICA DE PRIVACIDADE. O USUÁRIO ENTENDE E CONCORDA QUE O SEU USO E A PRESTAÇÃO DO SERVIÇO ENVOLVEM A COLETA E UTILIZAÇÃO DE INFORMAÇÕES E DADOS SOBRE O USUÁRIO (CONFORME DEFINIDO NA POLÍTICA DE PRIVACIDADE APLICÁVEL), INCLUINDO A TRANSFERÊNCIA DESTAS INFORMAÇÕES E DADOS PARA OUTROS TERRITÓRIOS, PARA FINS DE ARMAZENAMENTO, PROCESSAMENTO E UTILIZAÇÃO PELA UAI MOBI, SUA CONTROLADORA E DEMAIS EMPRESAS DO MESMO GRUPO ECONÔMICO, PARA AS FINALIDADES DA PRESTAÇÃO DO SERVIÇO E DEMAIS PREVISTAS NA POLÍTICA DE PRIVACIDADE. FAVOR CONSULTAR A POLÍTICA DE PRIVACIDADE DA UAI MOBI NO SEGUINTE LINK: ________. A POLÍTICA DE PRIVACIDADE DA UAI MOBI CONSTITUI PARTE INTEGRANTE DOS TERMOS.</span></p>
                                <p><span ><span ><span >3. </span></span><span ><strong>CADASTRO</strong></span></span></p>
                                <p><span ><strong><span >3.1.</span></strong></span><span ><span > </span></span><span >Para utilizar grande parte dos Serviços, o Usuário deve registrar-se e manter uma conta pessoal de Usuário de Serviços (“</span><span ><strong>Conta</strong></span><span >”). O Usuário deve ter capacidade civil, tendo idade mínima de 18 anos de idade para criar seu perfil, a menos que seja emancipado ou tenha obtido plena capacidade civil nos termos da legislação civil em vigor. Pais e responsáveis legais são responsáveis por avaliar a adequação da utilização de dispositivos móveis e dos Serviços para menores de 18 anos de idade. O Usuário que desejar utilizar o Serviço deverá obrigatoriamente preencher os campos de cadastro exigidos e responderá pela veracidade das informações declaradas, obrigando-se a manter informações válidas, atualizadas e corretas. O perfil do Usuário é exclusivo e intransferível. Em caso de não confirmação de seus dados, o acesso do Usuário ao Serviço poderá ser bloqueado, a exclusivo critério da UAI MOBI.</span></p>
                                <p><span ><strong><span >3.1.1.</span></strong></span><span ><span > </span></span><span >As informações da Conta são de exclusiva responsabilidade de quem as inseriu. No caso de acarretarem danos ou prejuízo de qualquer espécie, as medidas cabíveis podem ser tomadas pela UAI MOBI a fim de resguardar seus interesses e a integridade dos demais Usuários do Aplicativo.</span></p>
                                <p><span ><span ><span >3. </span></span><span ><strong>SERVIÇOS</strong></span></span></p>
                                <p><span ><strong><span >4.1.</span></strong></span><span ><span > </span></span><span ><strong>Serviços prestados pela UAI MOBI.</strong></span><span > Os Serviços consistem na disponibilização de uma aplicação tecnológica que possibilita ao Usuário devidamente cadastrado localizar e contatar prestadores de diferentes modalidades de serviços de transporte de pessoas (como, por exemplo, taxistas, mototaxistas ou motoristas privados) (“</span><span ><strong>Motorista(s) Parceiro(s)</strong></span><span >“). O Serviço não deve ser utilizado para qualquer finalidade que não as expressamente autorizadas por estes Termos.</span></p>
                                <p><span ><strong><span >4.1.1.</span></strong></span><span ><span > </span></span><span >O uso dos Serviços pelos Usuários é, em geral, gratuito. A UAI MOBI informará previamente o Usuário sobre funcionalidades e condutas que possam gerar cobranças e/ou pagamentos pelo Usuário de forma específica pelos Meios de Comunicação e/ou por atualização dos Termos. A UAI MOBI se reserva o direito de passar a cobrar pelo Serviço ou parte dele a qualquer tempo. O Usuário será previamente informado caso isso ocorra e terá a oportunidade de consentir com tais cobranças pelo Serviço ou cessar o uso do mesmo.</span></p>
                                <p><span ><strong><span >4.2.</span></strong></span><span ><span > </span></span><span ><strong>Modificação ou Encerramento do Serviço</strong></span><span >.</span><span ><span > </span></span><span >A UAI MOBI se reserva o direito de, a qualquer tempo, modificar ou descontinuar, temporariamente ou permanentemente, o Serviço ou parte dele, com ou sem notificação. O Usuário concorda que a UAI MOBI não será responsabilizada, nem terá qualquer obrigação adicional, implícita ou explícita, para com o Usuário ou terceiros em razão de qualquer modificação, suspensão ou desativação do Serviço.</span></p>
                                <p><span ><strong><span >4.3.</span></strong></span><span ><span > </span></span><span ><strong>SERVIÇO DE TRANSPORTE PRESTADO PELOS MOTORISTAS PARCEIROS. </strong></span><span >O USUÁRIO ENTENDE E DECLARA QUE A UAI MOBI NÃO PRESTA E NÃO ASSEGURA A PRESTAÇÃO DE QUALQUER SERVIÇO DE TRANSPORTE DE PESSOAS. A UAI MOBI NÃO POSSUI UMA FROTA DE VEÍCULOS, PRESTANDO EXCLUSIVAMENTE UM SERVIÇO DE INTERMEDIAÇÃO VOLTADO À FACILITAÇÃO DA CONTRATAÇÃO DE SERVIÇO DE TRANSPORTE DE PASSAGEIROS PERANTE UM MOTORISTA PARCEIRO CADASTRADO EM NOSSO APLICATIVO.</span></p>
                                <p><span ><strong><span >4.4.</span></strong></span><span ><span > </span></span><span ><strong>RESPONSABILIDADE PELOS SERVIÇOS DE TRANSPORTE. </strong></span><span >A CONTRATAÇÃO DOS SERVIÇOS DE TRANSPORTE DE PESSOAS É FEITA DIRETAMENTE ENTRE OS USUÁRIOS E OS MOTORISTAS PARCEIROS, TERCEIROS INDEPENDENTES QUE NÃO POSSUEM QUALQUER FORMA DE VÍNCULO EMPREGATÍCIO, SOCIETÁRIO OU DE SUBORDINAÇÃO COM A UAI MOBI, NEM DE QUALQUER DE SUAS AFILIADAS E SUA CONTROLADORA. A UAI MOBI NÃO SE RESPONSABILIZA POR QUAISQUER PERDAS, PREJUÍZOS OU DANOS DECORRENTES OU RELATIVOS AOS SERVIÇOS DE TRANSPORTE DE PESSOAS, CONFORME PREVISTO NA CLÁUSULA 8.2., ABAIXO.</span></p>
                                <p><span ><strong><span >4.5.</span></strong></span><span ><span > </span></span><span ><strong>Danos e prejuízos causados aos Motoristas Parceiros. </strong></span><span >O Usuário será responsável por quaisquer danos ou prejuízos que causar ao prestador de serviço de transporte de pessoas e concorda em indenizar e manter a UAI MOBI indene em relação a quaisquer demandas, perdas, prejuízos ou danos direta ou indiretamente relacionados a atos ou fatos causados pelo Usuário em face do prestador de serviço de transporte de pessoas.</span></p>
                                <p><span ><strong><span >4.5.1.</span></strong></span><span ><span > </span></span><span >O Usuário é o único integral responsável pelo conteúdo de bagagens transportadas durante a corrida, sendo expressamente vedado o transporte de armas de fogo, munições; materiais perigosos, explosivos, inflamáveis ou combustíveis; drogas e entorpecentes; e quaisquer outros materiais cujo transporte seja proibido pela legislação ou atente contra os bons costumes e a moralidade, respondendo o Usuário por qualquer infração à legislação em vigor e em qualquer âmbito.</span></p>
                                <p><span ><strong><span >4.6.</span></strong></span><span ><span > </span></span><span >A UAI MOBI disponibiliza ao Usuário a opção de avaliar e comentar qualitativamente o serviço de transporte realizado pelos Motoristas Parceiros, sendo vedada, sob pena de suspensão do uso do Aplicativo e dos Serviços, nos termos da Cláusula 10, a publicação de comentários de caráter difamatório, calunioso, violento, obsceno, pornográfico, racista, homofóbico, ilegal ou de qualquer outra forma ofensivo, assim entendido pela UAI MOBI a seu exclusivo critério.</span></p>
                                <p><span ><strong><span >4.6.1.</span></strong></span><span ><span > </span></span><span >O conteúdo publicado pelo Usuário é de sua única e exclusiva responsabilidade civil e penal e para todos os efeitos legais, inclusive em relação aos comentários e opiniões acerca de determinado conteúdo.</span></p>
                                <p><span ><strong><span >4.7.</span></strong></span><span ><span > </span></span><span ><strong>Acesso à Rede e Equipamentos. </strong></span><span >O Usuário entende e concorda que a utilização do Serviço demanda a aquisição de dispositivos de telefonia móvel e a contratação de serviços de telecomunicação, e que a utilização do Serviço poderá gerar cobranças por parte de tais prestadores de serviço de telecomunicação para conexão com a Internet, por exemplo. O Usuário é o responsável exclusivo por contratar e arcar com todo e qualquer custo e ônus relacionados à aquisição de seu dispositivo de telefonia móvel e a contratação de seu serviço de telecomunicação. A UAI MOBI não é responsável pela disponibilidade, qualidade e manutenção de tais serviços de telecomunicação e o Usuário entende que as condições do serviço de telecomunicações poderão afetar a experiência do Serviço. A UAI MOBI não será responsabilizada por qualquer problema relacionado ao Serviço decorrente direta ou indiretamente de inconsistências ou falhas nos dispositivos de telefonia móvel e/ou nos serviços de telecomunicação.</span></p>
                                <p><span ><strong><span >5.</span></strong></span><span > </span><span ><strong>CÓDIGOS PROMOCIONAIS</strong></span></p>
                                <p><span ><strong><span >5.1.</span></strong></span><span ><span > </span></span><span >A UAI MOBI poderá, a seu exclusivo critério, criar códigos promocionais que poderão ser resgatados para crédito na Conta ou outras características ou benefícios relacionados aos Serviços e/ou a serviços de parceiros da UAI MOBI, sujeitos a quaisquer condições adicionais que a UAI MOBI estabelecer para cada um dos códigos promocionais (“</span><span ><strong>Códigos Promocionais</strong></span><span >”).</span></p>
                                <p><span ><strong><span >5.1.1.</span></strong></span><span ><span > </span></span><span >O Usuário concorda que Códigos Promocionais: (i) devem ser usados de forma legal para a finalidade e o público a que se destinam; (ii) não devem ser duplicados, de qualquer forma vendidos, transferidos ou disponibilizados ao público em geral (seja por meio de postagem ao público ou qualquer outro método), a menos que expressamente permitido pela UAI MOBI; (iii) poderão ser desabilitados pela UAI MOBI a qualquer momento por motivos legalmente legítimos, sem que disto resulte qualquer responsabilidade para a UAI MOBI; (iv) somente poderão ser usados de acordo com as condições específicas que a UAI MOBI estabelecer para esses Código Promocional; (v) não são válidos como dinheiro; e (vi) poderão expirar antes de serem usados. A UAI MOBI se reserva o direito de reter ou deduzir créditos ou outras funcionalidades ou vantagens obtidas por meio do uso dos Códigos Promocionais pelo próprio Usuário ou por terceiros, caso a UAI MOBI apure ou acredite que o uso ou resgate do Código Promocional foi feito com erro, fraude, ilegalidade ou violação às condições do respectivo Código Promocional, de regulamentos específicos e dos Termos, a critério da UAI MOBI.</span></p>
                                <p><span ><strong><span >6.</span></strong></span><span > </span><span ><strong>LICENÇA</strong></span></p>
                                <p><span ><strong><span >6.1.</span></strong></span><span ><span > </span></span><span >Sujeito ao cumprimento destes Termos, a UAI MOBI outorga ao Usuário uma licença, gratuita, limitada, não exclusiva, pessoal, intransferível e revogável única e exclusivamente para acessar e utilizar o Serviço para fins pessoais, não comerciais por meio de qualquer dispositivo compatível.</span></p>
                                <p><span ><span ><span >7. </span></span><span ><strong>DO PAGAMENTO DO SERVIÇO DE TRANSPORTE INDIVIDUAL</strong></span></span></p>
                                <p><span ><strong><span >7.1.</span></strong></span><span ><span > </span></span><span ><strong>Preço.</strong></span><span > O Usuário entende que os serviços de transporte que receber de um Motorista Parceiro, contratados por meio dos Serviços, poderão ser cobrados (“</span><span ><strong>Preço(s)</strong></span><span >”). O pagamento do Preço é feito diretamente pelo Usuário, por uma das modalidades de pagamento disponíveis e que sejam aceitas pelos Motoristas Parceiros: (i) dinheiro; (ii) em sua própria máquina de cartão de débito ou crédito; e/ou (iii) por meio do próprio Aplicativo. A UAI MOBI não se responsabiliza por qualquer outra modalidade de pagamento, exceto por meio do próprio Aplicativo. Modalidades de pagamento diretas, a saber, dinheiro e máquina de cartão de débito ou crédito dos Motoristas Parceiros, são disponibilizados pelo próprio Motorista Parceiro e utilizados por conta e risco do Usuário.</span></p>
                                <p><span ><strong><span >7.1.1.</span></strong></span><span ><span > </span></span><span ><strong>Forma de cálculo do Preço. </strong></span><span >No momento em que o Usuário tenha inserido o destino e solicitado o Serviço através do Aplicativo, receberá o valor do Preço pelo trajeto correspondente. O Preço será composto por: (i) valor base/mínimo; acrescido de (ii) parcela variável baseada na distância e quantidade de tempo estimado para o trajeto. Além disso, em certas situações em que a demanda por viagens aumentar muito, é possível a incidência de ajuste adicional dinâmico (“</span><span ><strong>Ajuste Dinâmico</strong></span><span >”). O intuito do Ajuste Dinâmico é equilibrar oferta e demanda ao incentivar que mais motoristas parceiros se conectem ao aplicativo e aceitem solicitações de corrida. O Ajuste Dinâmico será parte integrante do Preço e sua incidência será clara. O Usuário entende e concorda que ao solicitar a viagem, aceita a cobrança do Preço indicado, comprometendo-se a pagar integralmente o referido Preço.</span></p>
                                <p><span ><strong><span >7.1.2.</span></strong></span><span ><span > </span></span><span ><strong>Variação do Preço após aceite da corrida. </strong></span><span >O Preço pode aumentar se você alterar o destino durante a viagem, se fizer paradas extras ao longo do trajeto, se for uma viagem longa que exija preço de retorno, se houver cobrança de pedágios ou se a viagem levar muito mais tempo do que o esperado, em decorrência de atos do Usuário, e quaisquer eventos que não estejam sob o controle do Motorista e/ou da UAI MOBI, incluindo caso fortuito e força maior (e.g. realização de eventos na cidade que alterem o tráfico, ocorrência de chuvas e acidentes naturais, etc.) (“</span><span ><strong>Hipóteses de Variação de Preço</strong></span><span >”). Nas Hipóteses de Variação do Preço, ao final da corrida, será cobrado adicional ao valor do Preço (“</span><span ><strong>Adicional ao Preço</strong></span><span >”).</span></p>
                                <p><span > </span></p>
                                <p><span ><strong><span >7.1.3.</span></strong></span><span ><span > </span></span><span >A UAI MOBI se reserva o direito de estabelecer, remover e/ou revisar o Preço relativo a todos os serviços de transporte por intermédio do uso dos Serviços a qualquer momento, a critério exclusivo da UAI MOBI. Ademais, você reconhece e concorda que o Preço aplicável em certas áreas geográficas poderão incluir cobranças, tarifas, taxas, impostos e/ou contribuições governamentais devidas em seu nome, inclusive, tarifas de pedágios ou cobranças de aeroportos, conforme a rota tomada pelo Motorista Parceiro, o local de origem e/ou destino da viagem.</span></p>
                                <p><span ><strong><span >7.1.4.</span></strong></span><span ><span > </span></span><span >A UAI MOBI envidará esforços razoáveis para informá-lo dos Preços que poderão se aplicar, sendo certo que o Usuário será responsável pelo pagamento dos Preços lançados em sua Conta. A UAI MOBI poderá, a qualquer momento, fornecer a certo(s) Usuário(s) ofertas e descontos promocionais que poderão resultar em valores diferentes cobrados por serviços de transporte iguais ou similares obtidos por meio do uso dos Serviços, e o Usuário concorda que essas ofertas e descontos promocionais, a menos que também a ele oferecidos, não terão influência sobre os Preços normalmente aplicados.</span></p>
                                <p><span ><strong><span >7.2.</span></strong></span><span ><span > </span></span><span ><strong>Taxa de Cancelamento. </strong></span><span >O Usuário poderá optar por cancelar sua solicitação dos Serviços de Transporte prestados pelo Motorista Parceiro a qualquer momento antes da chegada desse Motorista Parceiro ao local de embarque indicado no Aplicativo, caso em que poderá incidir uma taxa de cancelamento (“</span><span ><strong>Taxa de Cancelamento</strong></span><span >”). A Taxa de Cancelamento será destinada ao Motorista Parceiro como forma de ressarcimento pelo seu deslocamento e tempo de espera.</span></p>
                                <p><span ><strong><span >7.3.</span></strong></span><span ><span > </span></span><span ><strong>Meios de Pagamento. </strong></span><span >O pagamento por meio do Aplicativo se dará, à escolha do Usuário, seja por meio de conta em empresa parceira de meios de pagamento (“</span><span ><strong>Empresa de Pagamento</strong></span><span >”), seja por cartão de crédito devidamente cadastrado no Aplicativo (“</span><span ><strong>Empresa de Cartões de Crédito</strong></span><span >”). A UAI MOBI não possui qualquer responsabilidade por tal acesso e cadastro da Empresa Parceira ou da Empresa de Cartões de Crédito. Ao finalizar o processo de pagamento, o Usuário autoriza a UAI MOBI a fazer o débito do valor integral do Preço no Meio de Pagamento disponível.</span></p>
                                <p><span ><strong><span >7.3.1.</span></strong></span><span ><span > </span></span><span >Caso o Usuário opte por realizar o pagamento utilizando cartão de crédito, ele fornecerá seus dados no Aplicativo e esses dados serão enviados à Empresa de Cartão de Crédito. Para sua segurança, sempre que o Usuário cadastrar um novo cartão de crédito, a UAI MOBI realizará uma cobrança-teste no valor de R$ 5,00 (cinco reais), que será estornada logo em seguida. Essa cobrança-teste não será lançada na fatura, e serve apenas para verificar a validade do cartão de crédito cadastrado.</span></p>
                                <p><span ><strong><span >7.3.2.</span></strong></span><span ><span > </span></span><span >Caso não seja possível debitar Preço do valor da corrida no Meio de Pagamento disponível da Empresa de Pagamentos por qualquer razão, a UAI MOBI gerará um título de cobrança e poderá utilizar de todo e qualquer meio judicial ou extrajudicial visando ao recebimento do valor devido.</span></p>
                                <p><span ><strong><span >7.4.</span></strong></span><span ><span > </span></span><span ><strong>Gorjeta.</strong></span><span > A UAI MOBI poderá oferecer ao Usuário uma funcionalidade por meio da qual seja possível ofertar ao Motorista Parceiro uma gorjeta, como forma de incentivo a um serviço rápido, atencioso e eficiente. Essa gorjeta não será obrigatória.</span></p>
                                <p><span ><strong><span >7.5.</span></strong></span><span > </span><span ><strong>Divisão de Pagamentos.</strong></span><span > A UAI MOBI poderá oferecer ao Usuário uma funcionalidade por meio da qual seja possível dividir o Preço com outros Usuários. O Preço será dividido igualmente entre os Usuários que aceitarem a divisão. A UAI MOBI poderá cobrar valor adicional pelo uso dessa funcionalidade, conforme informações exibidas no aplicativo.</span></p>
                                <p><span ><span >8. </span></span><span ><strong>RECUSA DE GARANTIA; LIMITAÇÃO DE RESPONSABILIDADE; INDENIZAÇÃO</strong></span></p>
                                <p><span ><strong><span >8.1.</span></strong></span><span > </span><span ><strong>RECUSA DE GARANTIA.</strong></span><span > O USUÁRIO EXPRESSAMENTE CONCORDA E ESTÁ CIENTE DE QUE: (i) A UTILIZAÇÃO DO SERVIÇO SERÁ SOB INTEIRA RESPONSABILIDADE DO USUÁRIO. O SERVIÇO É FORNECIDO AO USUÁRIO NA FORMA EM QUE ESTÁ DISPONÍVEL. A UAI MOBI NÃO OFERECE GARANTIAS OUTRAS ALÉM DAS EXPRESSAMENTE ESTABELECIDAS NESTES TERMOS; E (ii) A UAI MOBI NÃO PODE GARANTIR QUE: (A) O SERVIÇO À DISPOSIÇÃO ATENDERÁ ÀS NECESSIDADES DO USUÁRIO; (B) O SERVIÇO SERÁ PRESTADO DE FORMA ININTERRUPTA, TEMPESTIVA; (C) QUE A QUALIDADE DE QUAISQUER OUTRAS OFERTAS, INFORMAÇÕES OU OUTRO MATERIAL ACESSADO, OBTIDO, DISPONIBILIZADO OU PRESTADO AO USUÁRIO EM CONEXÃO AO SERVIÇO ATENDERÁ ÀS EXPECTATIVAS; (D) QUE MELHORAMENTOS OU INOVAÇÕES SERÃO IMPLEMENTADOS; E (E) OS SERVIÇOS DE TRANSPORTE SOLICITADOS POR MEIO DO USO DOS SERVIÇOS SERÃO CONFIÁVEIS, PONTUAIS E ADEQUADOS.</span></p>
                                <p><span ><strong><span >8.1.1.</span></strong></span><span ><span > </span></span><span >O USUÁRIO EXPRESSAMENTE CONCORDA E ESTÁ CIENTE DE QUE A UAI MOBI NÃO TERÁ QUALQUER RESPONSABILIDADE, SEJA CONTRATUAL OU EXTRA-CONTRATUAL, POR QUAISQUER DANOS PATRIMONIAIS OU MORAIS, INCLUINDO, SEM LIMITAÇÃO, DANOS POR LUCROS CESSANTES OU DE INFORMAÇÕES OU OUTRAS PERDAS INTANGÍVEIS RESULTANTES DO: (A) USO OU INCAPACIDADE DE USAR O SERVIÇO; (B) QUEBRAS DE SEGURANÇA E ACESSO NÃO AUTORIZADO ÀS TRANSMISSÕES OU INFORMAÇÕES DO USUÁRIO, BEM COMO DA ALTERAÇÃO DESTAS; (C) ORIENTAÇÕES OU CONDUTAS DE TERCEIROS SOBRE O SERVIÇO; E (D) POR MOTIVOS DE FORÇA MAIOR OU CASO FORTUITO, ATOS PRATICADOS PELO PRÓPRIO USUÁRIO E ATOS PRATICADOS POR OU SOB A RESPONSABILIDADE DE TERCEIROS.</span></p>
                                <p><span ><strong><span >8.2.</span></strong></span><span ><span > </span></span><span ><strong>LIMITAÇÃO DE RESPONSABILIDADE PELOS SERVIÇOS DE TRANSPORTE. </strong></span><span >O USUÁRIO RECONHECE E CONCORDA QUE OS MOTORISTAS PARCEIROS SÃO AUTÔNOMOS E RESPONSÁVEIS EXCLUSIVOS PELA PRESTAÇÃO DOS SERVIÇOS DE TRANSPORTE. A UAI MOBI ADOTARÁ AS MEDIDAS POSSÍVEIS DENTRO DE SEU ÂMBITO DE ATUAÇÃO PARA QUE O USUÁRIO TENHA A MELHOR EXPERIÊNCIA POSSÍVEL TAMBÉM COM OS SERVIÇOS DE TRANSPORTE PRESTADOS PELOS MOTORISTAS PARCEIROS, MAS A UAI MOBI EXPRESSAMENTE SE ISENTA DE PRESTAR QUALQUER GARANTIA E NÃO SE RESPONSABILIZA POR: (I) QUALQUER MOTORISTA PARCEIRO, INCLUINDO QUAISQUER DE SEUS ATRIBUTOS TÉCNICOS OU PESSOAIS; (II) A AVALIAÇÃO E INSPEÇÃO CONTÍNUA DE TAIS MOTORISTAS PARCEIROS E SEUS RESPECTIVOS VEÍCULOS; E (III) QUAISQUER ATOS, FATOS, PERDAS, DANOS E PREJUÍZOS RELACIONADOS DIRETA OU INDIRETAMENTE AOS SERVIÇOS DE TRANSPORTE DE PESSOAS PRESTADOS PELOS MOTORISTAS PARCEIROS, INCLUINDO MAS SEM LIMITAÇÃO: (A) O ITINERÁRIO OBSERVADO PELO MOTORISTA PARCEIRO; (B) O HORÁRIO DE CHEGADA DO USUÁRIO AO DESTINO FINAL; (C) O ENCERRAMENTO ADEQUADO DA CORRIDA PELO CONDUTOR NA PLATAFORMA; E (D) A GUARDA DE OBJETOS ESQUECIDOS PELO USUÁRIO NO VEÍCULO DO CONDUTOR.</span></p>
                                <p><span ><strong><span >8.2.1.</span></strong></span><span ><span > </span></span><span >O Usuário está ciente de que os dados do Motorista Parceiro divulgados pela UAI MOBI, incluindo, mas não se limitando a isto, seu nome completo, modelo e placa do veículo utilizado, foram fornecidos pelo próprio Motorista Parceiro.</span></p>
                                <p><span ><strong><span >8.3.</span></strong></span><span ><span > </span></span><span ><strong>INDENIZAÇÃO.</strong></span></p>
                                <p><span ><strong><span >8.3.1.</span></strong></span><span ><span > </span></span><span ><strong>POR ACIDENTES.</strong></span><span > O USUÁRIO CONCORDA QUE A UAI MOBI NÃO TEM RESPONSABILIDADE INDENIZATÓRIA POR QUALQUER ACIDENTE ENVOLVENDO USUÁRIO E MOTORISTA PARCEIRO; QUE QUALQUER PAGAMENTO FEITO COM BASE NO SEGURO CONTRA ACIDENTES PESSOAIS DE PASSAGEIROS (APP) MANTIDO PELA UAI MOBI OU PELO PRESTADOR TERCEIRO REDUZIRÁ QUALQUER INDENIZAÇÃO QUE EVENTUALMENTE SEJA DEVIDA DECORRENTE DAQUELE MESMO ACIDENTE. A UAI MOBI PODERÁ DEDUZIR QUALQUER PAGAMENTO DO SEGURO REALIZADO DE QUALQUER VALOR INDENIZATÓRIO QUE A UAI MOBI VENHA A SER OBRIGADA A PAGAR, COM BASE EM QUALQUER FUNDAMENTO.</span></p>
                                <p><span ><strong><span >8.3.2.</span></strong></span><span ><span > </span></span><span ><strong>DO USUÁRIO À UAI MOBI. </strong></span><span >O Usuário concorda em indenizar e isentar a UAI MOBI e sua controladora, subsidiárias, afiliadas, diretores e empregados, parceiros e clientes que exibam suas marcas ou de sua controladora, por quaisquer perdas e danos que venham a ser demandados, incluindo despesas judiciais e honorários advocatícios, em razão: (i) de qualquer fato ou ato ilícito praticado pelo Usuário; (ii) do uso dos Serviços, de serviços de transporte obtidos por meio do uso dos Serviços; (ii) pela violação destes Termos; ou (iv) violação dos direitos de terceiros, inclusive Motoristas Parceiros.</span></p>
                                <p><span ><span >9. </span></span><span ><strong>PROPRIEDADE INTELECTUAL</strong></span></p>
                                <p><span ><strong><span >9.1.</span></strong></span><span ><span > </span></span><span >As marcas, nomes, logotipos, nomes de domínio e demais sinais distintivos, bem como todo e qualquer conteúdo, desenho, arte ou </span><span ><em>index</em></span><span > publicado no Aplicativo e o próprio Serviço, são de propriedade exclusiva da UAI MOBI.</span></p>
                                <p><span ><strong><span >9.2.</span></strong></span><span ><span > </span></span><span >São vedados quaisquer atos ou contribuições tendentes à descompilação, engenharia reversa, modificação das características, ampliação, alteração, mesclagem ou incorporação em quaisquer outros programas ou sistemas do Aplicativo ou Serviço. Enfim, toda e qualquer forma de reprodução do Aplicativo ou Serviço, total ou parcial, permanente, temporária ou provisória, de forma gratuita ou onerosa, sob quaisquer modalidades, formas ou títulos é expressamente vedada.</span></p>
                                <p><span ><span ><span >10. </span></span><span ><strong>CANCELAMENTO DE SEU ACESSO AO APLICATIVO</strong></span></span></p>
                                <p><span ><strong><span >10.1.</span></strong></span><span ><span > </span></span><span >O Usuário concorda que a UAI MOBI, à sua livre discrição, poderá cancelar sua utilização do Serviço, incluindo, mas não se limitando: (i) por descumprimentos e/ou violação destes Termos; (ii) em função de ordem judicial ou requisição legal e competente de autoridade governamental; (iii) por requisição do próprio Usuário; (iv) por desativação ou modificação do Serviço (ou de qualquer de suas partes); (v) por caso fortuito, força maior e/ou questões de segurança; (vi) por inatividade da conta por um período razoável de tempo; (vii) pela suposta prática de qualquer atividade fraudulenta ou ilegal por parte do Usuário, a critério da UAI MOBI; (viii) ao uso inadequado do Aplicativo, a critério da UAI MOBI; e/ou (ix) por inadimplemento por parte do Usuário de quaisquer obrigações, valores, pagamentos devidos em razão do Serviço, quando aplicável.</span></p>
                                <p><span ><strong><span >10.2.</span></strong></span><span ><span > </span></span><span >O USUÁRIO CONCORDA QUE O TÉRMINO DE SEU ACESSO AO SERVIÇO, POR QUALQUER RAZÃO CONSTANTE DESTES TERMOS, PODE OCORRER SEM UMA NOTIFICAÇÃO PRÉVIA E TODAS AS INFORMAÇÕES E DADOS CONSTANTES PODERÃO SER PERMANENTEMENTE APAGADOS.</span></p>
                                <p><span ><span ><span >11. </span></span><span ><strong>DISPOSIÇÕES FINAIS</strong></span></span></p>
                                <p><span ><strong><span >11.1. </span></strong></span><span >Estes termos constituem o acordo integral entre o Usuário e a UAI MOBI e regerão a utilização do Serviço pelo Usuário, substituindo qualquer acordo anterior.</span></p>
                                <p><span ><strong><span >11.2.</span></strong></span><span ><span > </span></span><span ><strong>Legislação Aplicável. </strong></span><span >Estes Termos e o relacionamento entre o Usuário e a UAI MOBI serão regidos pelas leis da República Federativa do Brasil. Fica eleito o foro da Comarca de Barão de Cocais como sendo o único competente para dirimir quaisquer litígios e/ou demandas que venham a envolver as partes em relação ao uso e acesso de seu site e Aplicativo. O Usuário e a UAI MOBI concordam em submeter-se à competência única e exclusiva dos tribunais localizados no Brasil.</span></p>
                                <p><span ><strong><span >11.3.</span></strong></span><span ><span > </span></span><span ><strong>Notificações. </strong></span><span >Todas as notificações, intimações, ofícios ou qualquer outra forma oficial de cientificação da UAI MOBI deverão ocorrer em seu endereço de sede.</span></p>
                                <p><span ><strong><span >11.4.</span></strong></span><span ><span > </span></span><span ><strong>Renúncia de Direitos. </strong></span><span >Caso a UAI MOBI deixe de exercer ou executar qualquer direito ou dispositivo destes Termos, isto não será caracterizado como uma renúncia a tal direito ou dispositivo nem constituirá novação. Se qualquer dispositivo dos Termos for considerado inválido por um tribunal competente, as partes todavia concordam que o restante dos Termos deverá se interpretado de tal forma a refletir a intenção original, e os outros dispositivos dos Termos permanecerão em pleno vigor e efeito.</span></p>
                                <p><span ><strong><span >11.5.</span></strong></span><span ><span > </span></span><span ><strong>Cessão de Direitos.</strong></span><span > O Usuário não poderá ceder tampouco transferir estes Termos, total ou parcialmente, sem prévia aprovação por escrito da UAI MOBI.  O Usuário concede sua aprovação para que a UAI MOBI ceda e transfira estes Termos total ou parcialmente, inclusive: (i) para sua controladora, subsidiária ou afiliada; (ii) um adquirente das participações acionárias, negócios ou bens da UAI MOBI; ou (iii) para um sucessor em razão de qualquer operação societária. Não existe joint-venture, sociedade, emprego ou relação de representação entre o Usuário, a UAI MOBI ou quaisquer Motoristas Parceiros como resultado do contrato entre você e a UAI MOBI ou pelo uso dos Serviços.</span></p>
                                <p><span ><strong><span >11.6.</span></strong></span><span ><span > </span></span><span ><strong>Indivisibilidade. </strong></span><span >Caso qualquer disposição destes Termos seja tida como ilegal, inválida ou inexequível total ou parcialmente, por qualquer legislação, essa disposição ou parte dela será, naquela medida, considerada como não existente para os efeitos destes Termos, mas a legalidade, validade e exequibilidade das demais disposições contidas nestes Termos não serão afetadas. Nesse caso, as partes substituirão a disposição ilegal, inválida ou inexequível, ou parte dela, por outra que seja legal, válida e exequível e que, na máxima medida possível, tenha efeito similar à disposição tida como ilegal, inválida ou inexequível para fins de conteúdo e finalidade dos presentes Termos. Estes Termos constituem a totalidade do acordo e entendimento das partes sobre este assunto e substituem e prevalecem sobre todos os entendimentos e compromissos anteriores sobre este assunto. Nestes Termos, as palavras “inclusive” e “inclui” significam “incluindo, sem limitação”.</span></p>
                                <p> </p>
                            </div>
                            </div>
                        </div>
                        <a className='buttonJoin'
                        href='https://play.google.com/store/apps/details?id=br.com.uaimobi.passenger.drivermachine&hl=pt_BR&gl=US'
                            target='self'>
                            <button className='buttons'>
                                Cadastrar
                            </button>
                        </a>

                    </Section>
                </div>
            </div>
            <Footer />
        </Fragment>

    );
};



export default about;


