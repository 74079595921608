import React, { useState, useEffect } from 'react';

import logo from '../../../assets/img/logo.png';
import LinkHome from '../Link/Link';
import { Link } from 'react-router-dom'
const Nav = ({ inHome }) => {
  const [navClass, setNavClass] = useState('');
  const [toggeledNav, settoggeledNav] = useState(false);
  const toggleNav = () => {
    settoggeledNav(!toggeledNav);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      let navClass = '';
      if (window.scrollY >= 200) {
        navClass = 'scrolled';
      }
      setNavClass(navClass);
    });
  }, []);
  return (
    <nav className={`navbar navbar-expand-md bg-light ${navClass}`}>
      <div className='container'>
        <Link to='/'>
          <img height="75" width="90" src={logo} alt='logo' />

        </Link>
        <a className='navbar-brand' href='/'>

          <span>UaiMobi</span>
        </a>
        <div
          className={`navbar-toggler nav-icon ${(() => {
            if (toggeledNav) return 'open';
            return '';
          })()}`}
          onClick={toggleNav}
        >
          <span />
          <span />
          <span />
        </div>

        <div
          className={`collapse navbar-collapse ${(() => {
            if (toggeledNav) return 'show';
            return '';
          })()}`}
        >
          {inHome ?
            <ul className='navbar-nav ml-auto'>
              <li className='nav-item'>
                <LinkHome target='home' classes='nav-link'>
                  Página Inicial
              </LinkHome>
              </li>
              <li className='nav-item'>
                <LinkHome target='join' classes='nav-link'>
                  Download
              </LinkHome>
              </li>
              <li className='nav-item'>
                <LinkHome target='about' classes='nav-link'>
                  Sobre nós
              </LinkHome>
              </li>
              <li className='nav-item'>
                <LinkHome target='services' classes='nav-link'>
                  Serviços
              </LinkHome>
              </li>
              {/* <li className='nav-item'>
                <Link to='/motoristas' className='nav-link'>
                  Motoristas
              </Link>
              </li> */}
              <li className='nav-item'>
                <LinkHome target='contact' classes='nav-link'>
                  Contate-nos
              </LinkHome>
              </li>
            </ul>
            :
            <ul className='navbar-nav ml-auto'>
              <li className='nav-item'>
                <Link to='/' className='nav-link'>
                  Página Inicial
              </Link>
              </li>
              <li className='nav-item'>
                <Link to='/' className='nav-link'>
                  Download
              </Link>
              </li>
              <li className='nav-item'>
                <Link to='/' className='nav-link'>
                  Sobre nós
              </Link>
              </li>
              <li className='nav-item'>
                <Link to='/' className='nav-link'>
                  Serviços
              </Link>
              </li>
              {/* <li className='nav-item'>
                <Link to='/motoristas' className='nav-link'>
                  Motoristas
              </Link>
              </li> */}
              
              <li className='nav-item'>
                <Link to='/' className='nav-link'>
                  Contate-nos
              </Link>
              </li>
            </ul>}

        </div>
      </div>
    </nav>
  );
};

export default Nav;
