import React from 'react';

import Section from '../../../HOC/Section';

const Service = () => {
  return (
    <Section id='services'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Nossos </span>Serviços
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
          Sabemos que nas cidades interioranas o mais comum é de sempre 
          procurar cidades vizinhas, seja para passeios, visita à parentes 
          próximos ou até mesmo para consultas médicas. Por isso oferecemos 
          as corridas intermunicipais, assim o usuário tem a comodidade de 
          viajar de forma privada e sem precisar se preocupar com os 
          itinerários de ônibus ou trem. E o melhor, com um ótimo custo 
          benefício.
          </h6>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-md-6 col-md-offset-6'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className="fas fa-map-signs"/>
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Corridas municipais</h5>
                  <p className='service-description'>
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-md-offset-6'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className="fas fa-road"/>
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Corridas intermunicipais</h5>
                  <p className='service-description'>
                  </p>
                </div>
              </div>
            </div>            
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Service;
