import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/main.scss';
import App from './App';
import { BrowserRouter, Switch, Route, useLocation, withRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import about from './pages/about';
import termsDriver from './pages/termsDriver';
import driver from './pages/driver';
import termsPassenger from './pages/termsPassenger';
import privacyPolicy from './pages/privacyPolicy';




function _ScrollToTop(props) {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)


ReactDOM.render(
    <BrowserRouter>
        <Switch >
            <ScrollToTop>
                <Route exact path='/' component={App} />
                <Route exact path='/sobre' component={about} />
                <Route exact path='/termosMotorista' component={termsDriver} />
                <Route exact path='/termosPassageiro' component={termsPassenger} />
                <Route exact path='/politicaDePrivacidade' component={privacyPolicy} />
                <Route exact path='/motoristas' component={driver} />

            </ScrollToTop>

        </Switch>
    </BrowserRouter>
    , document.getElementById('root'),

);

serviceWorker.unregister();
