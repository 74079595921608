import React, { Fragment } from 'react';

import Home from './Home/Home';
import About from './About/About';
import Service from './Service/Service';
import Contact from './Contact/Contact';
import MapMarker from './MapMarker/MapMarker';
import Citys from './Citys/Citys';
import Join from './Join/Join';

const sections = () => {
  return (
    <Fragment>
      <Home />
      <About />
      <Service />
      <Citys />
      <Join/>
      <Contact />
      <MapMarker />
    </Fragment>
  );
};

export default sections;
