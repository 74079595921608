import React from 'react';

import Nav from '../Nav/Nav';
import TopBar from '../TopBar/TopBar';

const header = ({inHome}) => {
  return (
    <div className='header'>
      <TopBar />
      <Nav inHome={inHome}/>
    </div>
  );
};

export default header;
