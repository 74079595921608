import React, { Fragment } from 'react';

import Section from '../HOC/Section';
import Header from '../components/UI/Header/Header'
import Footer from '../components/UI/Footer/Footer'
const about = () => {
    return (
        <Fragment>
            <Header inHome={false} />
            <div>
                <div>
                    <Section id='pageAbout'>
                        <div className='container pt-2 pb-5'>
                            <div className='section-header pt-5 pb-5 text-center'>
                                <h3 className='title-page'>
                                    <span><br /><br /><br />
                                        Políticas de
                                    </span> Privacidade
                                </h3>
                            </div>
                            <div className='row'>
                                <div className='about-description'>
                                    <p><span ><strong><span >Informações Importantes:</span></strong></span></p>
                                    <p><span ><span >A Política de Privacidade (“Política de Privacidade“) que você está acessando integra os Termos e Condições de Uso e descreve como a UaiMobi TECNOLOGIA LTDA. (“UaiMobi”) e as suas subsidiárias, afiliadas, sociedades coligadas e sua controladora (“Afiliadas“) realizam a coleta, armazenamento, uso, processamento, associação, proteção, compartilhamento e demais formas de tratamento das suas informações, incluindo os seus dados pessoais, em conexão com o Aplicativo e os Serviços regidos pelos Termos e Condições de Uso.</span></span></p>
                                    <p><span ><span >A UaiMobi está comprometida com a sua privacidade e, por isso, disponibiliza esta Política de Privacidade em seu site para que você possa acessar a versão mais atualizada sempre que desejar.</span></span></p>
                                    <p><span ><span >ESTA POLÍTICA DE PRIVACIDADE ESTABELECE AS CONDIÇÕES DE TRATAMENTO DOS SEUS DADOS NECESSÁRIAS PARA A OPERAÇÃO DO APLICATIVO DA UaiMobi E O FORNECIMENTO DOS SERVIÇOS DA UaiMobi, INDISTINTAMENTE PARA MOTORISTAS, TAXISTAS E PASSAGEIROS. CASO VOCÊ NÃO CONCORDE COM ALGUMA DISPOSIÇÃO DESTA POLÍTICA DE PRIVACIDADE, VOCÊ NÃO DEVE UTILIZAR O APLICATIVO DA UaiMobi E OS SERVIÇOS DA UaiMobi. VOCÊ COMPREENDE E CONCORDA DE MANEIRA EXPRESSA QUE A UaiMobi PODERÁ COLETAR, ARMAZENAR, PROCESSAR, ASSOCIAR, COMPARTILHAR, UTILIZAR, DIVULGAR OU DE OUTRO MODO TRATAR AS SUAS INFORMAÇÕES, INCLUINDO SEUS DADOS PESSOAIS, SOB QUALQUER DAS FORMAS E PARA TODAS AS FINALIDADES PREVISTAS NESTA POLÍTICA DE PRIVACIDADE. </span></span></p>
                                    <p><span ><span >Esta Política de Privacidade está dividida em 5 seções:</span></span></p>
                                    <p><span >      <span >Dados coletados;</span></span></p>
                                    <p><span >      <span >Proteção e Armazenamento;</span></span></p>
                                    <p><span >      <span >Uso e Compartilhamento;</span></span></p>
                                    <p><span >      <span >Consentimento; e</span></span></p>
                                    <p><span >      <span >Exclusão de dados pessoais.</span></span></p>
                                    <p><span ><span >As regras estabelecidas por esta Política de Privacidade são restritas ao uso do Aplicativo e dos Serviços descritos nos Termos e Condições de Uso, fornecidos pela UaiMobi no Brasil. Esta Política de Privacidade não se aplica a oferta realizada por Afiliadas, outras empresas ou indivíduos, inclusive produtos ou sites que usam a Marca da UaiMobi. VOCÊ DEVERÁ CONSULTAR AS POLÍTICAS DE PRIVACIDADE DESSAS OUTRAS EMPRESAS PARA ENTENDER COMO ELAS TRATARÃO OS SEUS DADOS PESSOAIS.</span></span></p>
                                    <p><span ><span >Ao utilizar o Aplicativo e Serviços fornecidos pela UaiMobi, você estará concordando integralmente com as regras desta Política de Privacidade. Portanto, leia com atenção e, se tiver alguma dúvida, entre em contato com o serviço de Suporte ao Cliente.</span></span></p>
                                    <p><span > </span></p>
                                    <p><span ><strong><span >Informações coletadas</span></strong></span></p>
                                    <p><span > </span></p>
                                    <p><span ><span >Quando você se cadastra e utiliza os Serviços, você fornece e a UaiMobi coleta informações estatísticas de utilização dos Serviços, dados agregados e dados de identificação pessoal. Essas informações passam a ser tratadas conforme esta Política de Privacidade e podem ser utilizadas nas situações e para as finalidades descritas na [Seção 3 abaixo].</span></span></p>
                                    <p><span ><span >A UaiMobi pode coletar suas informações, incluindo dados aptos a identificar você, em diversas circunstâncias, incluindo:</span></span></p>
                                    <p><span ><span >Quando da criação do seu cadastro na UaiMobi;</span></span></p>
                                    <p><span ><span >Quando você acessa nosso site ou utiliza  Serviços da UaiMobi; e</span></span></p>
                                    <p><span ><span >Durante a utilização do Aplicativo, enquanto este permanecer ativo, em primeiro e em segundo plano (quando a aplicação está aberta mas não é exibida na tela do dispositivo), inclusive durante as suas viagens.</span></span></p>
                                    <p><span ><span >Ao se cadastrar na UaiMobi, você fornece determinadas informações, que podem incluir o seu nome, foto, endereço, número de telefone, e-mail, número do CPF, CNH, RENAVAM, sexo, data de nascimento, RG e comprovante de residência. Outras informações podem ser coletadas e processadas pela UaiMobi em razão de normas legais que lhe sejam aplicáveis.</span></span></p>
                                    <p><span ><span >A UaiMobi também requer que, para a função de “Contatos de Confiança”, você conceda permissão de acesso à sua Lista de Contatos. Se você não conceder acesso a sua Lista de Contatos, você não poderá utilizar a função “Contatos de Confiança”.</span></span></p>
                                    <p><span ><span >Conforme você usa o Aplicativo e/ou os Serviços da UaiMobi, inclusive durante as suas viagens, informações adicionais relativas a você podem ser obtidas pela UaiMobi, como a sua imagem, voz, posição geográfica, rota, duração da viagem, endereço IP, navegador utilizado, SSID e força do sinal das redes sem fio próximas, informações de cookies, tipo e marca do aparelho celular, identificadores de dispositivos móveis, versão do sistema operacional, aplicativos instalados, informações sobre rede, provedor de conexão à Internet utilizado, configuração dos dispositivos, dados de software, locais habituais de embarque e desembarque, itinerários, as avaliações que você faz sobre os Serviços da UaiMobi, dados financeiros, as formas de pagamento utilizadas e seus valores, as comunicações realizadas através do Aplicativo da UaiMobi e as comunicações realizadas entre você e a UaiMobi por meio dos nossos canais de atendimento.</span></span></p>
                                    <p><span ><span >Caso você não forneça qualquer das informações solicitadas, a UaiMobi não poderá garantir a qualidade e precisão do Aplicativo e dos Serviços da UaiMobi. Nesse caso, a UaiMobi terá o direito de excluir você da plataforma UaiMobi imediatamente, inclusive para assegurar o funcionamento regular e seguro do Aplicativo e dos Serviços para os demais usuários.</span></span></p>
                                    <p><span ><span >A UaiMobi também pode obter informações sobre você provenientes de bancos de dados públicos e privados, parceiros comerciais e/ou de outros prestadores de serviço quando você vincular sua Conta a esses prestadores (“Terceiros“), desde que tais Terceiros tenham obtido as informações em conformidade com a legislação aplicável. Essas informações poderão ser associadas às demais informações que a UaiMobi coleta sobre você e também utilizadas nas situações e para as finalidades descritas na Seção 3 abaixo. As informações fornecidas por Terceiros à UaiMobi obedecem à disciplina própria estabelecida por tais Terceiros e poderão estar sujeitos às suas respectivas políticas de privacidade.</span></span></p>
                                    <p><span > </span></p>
                                    <p><span ><strong><span >Proteção e Armazenamento</span></strong></span></p>
                                    <p><span > </span></p>
                                    <p><span ><span >A UaiMobi coleta de informações pessoais suas e, por essa razão, a segurança de seus dados é prioridade.</span></span></p>
                                    <p><span ><span >A UaiMobi emprega práticas de segurança da informação adequadas em todos os seus sistemas e infraestrutura, incluindo medidas físicas e digitais de segurança visando garantir a proteção de suas informações. Essas medidas incluem controles de acessos, criptografia forte, implementação SSL, firewalls, registro de log de alteração, duplo fator de autenticação, entre outros mecanismos e protocolos. Com relação a dados de natureza financeira, são aplicadas medidas adicionais de segurança conforme a regulamentação aplicável.</span></span></p>
                                    <p><span ><span >As informações coletadas pela UaiMobi podem ser armazenadas em servidores localizados no Brasil ou no exterior, a exclusivo critério da UaiMobi. Você declara compreender e concordar que outros países podem ter níveis de proteção de dados diferentes do Brasil. Sem prejuízo, suas informações porventura armazenadas em outros países estarão sujeitas a medidas de segurança pelo menos equivalentes àquelas descritas no parágrafo anterior.</span></span></p>
                                    <p><span ><span >As medidas de segurança acima descritas aplicam-se às suas informações somente a partir do momento em que a UaiMobi as recebe e enquanto as mantém sob sua guarda.  O funcionamento e a segurança do dispositivo que você usa para acessar o Aplicativo e os Serviços da UaiMobi, bem como da rede pela qual os dados trafegam não são de responsabilidade da UaiMobi. Visando garantir um ambiente com maior segurança, a UaiMobi sugere (sem que isso represente garantia contra incidentes) que você respeite as seguintes precauções básicas de segurança:</span></span></p>
                                    <p><span ><span >Não confie em e-mails estranhos;</span></span></p>
                                    <p><span ><span >Não acesse sites suspeitos;</span></span></p>
                                    <p><span ><span >Mantenha mecanismos de proteção ativos e atualizados, como anti-vírus e anti-malware; </span></span></p>
                                    <p><span ><span >Não instale aplicativos ou programas de fontes estranhas ou ilegais; e</span></span></p>
                                    <p><span ><span >Não acesse “conteúdo promocional” proveniente de fontes desconhecidas.</span></span></p>
                                    <p><span > </span></p>
                                    <p><span ><strong><span >Uso e Compartilhamento</span></strong></span></p>
                                    <p><span > </span></p>
                                    <p><span ><span >As suas informações, incluindo seus dados pessoais, são utilizadas para prestar e aprimorar, estética e funcionalmente, o Aplicativo e os Serviços da UaiMobi, além das outras finalidades previstas nesta Seção. Com exceção das hipóteses previstas abaixo, a UaiMobi não compartilhará suas informações com terceiros.</span></span></p>
                                    <p><span ><span >Em específico, a UaiMobi utilizará as suas informações para as seguintes finalidades:</span></span></p>
                                    <p><span ><strong><span >(i) </span></strong></span><span >prestar, fornecer, manter, desenvolver, aprimorar, divulgar e ampliar a utilização do Aplicativo e dos Serviços da UaiMobi; </span></p>
                                    <p><span ><strong><span >(ii)</span></strong></span><span ><span > </span></span><span >identificar você e personalizar a sua experiência e utilização do Aplicativo e dos Serviços da UaiMobi, inclusive para fornecimento de promoções e incentivos financeiros (saiba mais);</span></p>
                                    <p><span ><strong><span >(iii)</span></strong></span><span ><span > </span></span><span >gerar análises e relatórios estatísticos sobre o funcionamento e operação do Aplicativo e dos Serviços da UaiMobi, em benefício da UaiMobi e/ou dos seus parceiros;</span></p>
                                    <p><span ><strong><span >(iv)</span></strong></span><span ><span > </span></span><span >possibilitar que a UaiMobi entre em contato com você a respeito do Aplicativo e dos Serviços que disponibiliza, bem como para prestar suporte ao usuário;</span></p>
                                    <p><span ><strong><span >(v)</span></strong></span><span ><span > </span></span><span >disponibilizar e melhorar mecanismos de segurança e monitoramento do Aplicativo e dos Serviços da UaiMobi, inclusive o monitoramento de segurança durante uma viagem (saiba mais);</span></p>
                                    <p><span ><strong><span >(vi)</span></strong></span><span ><span > </span></span><span >detectar e proteger a UaiMobi e os usuários contra fraudes, abusos ou atos ilegais;</span></p>
                                    <p><span ><strong><span >(vii)</span></strong></span><span ><span > </span></span><span >detectar e impedir o uso de aplicativos e programas para propósitos fradulentos ou que visem a alterar as funcionalidades do Aplicativo e dos Serviços fornecidos pela UaiMobi (saiba mais); </span></p>
                                    <p><span ><strong><span >(viii)</span></strong></span><span > desenvolver novos produtos, serviços e funcionalidades;</span></p>
                                    <p><span ><strong><span >(xi)</span></strong></span><span ><span > </span></span><span >realizar pesquisas e análises científicas;</span></p>
                                    <p><span ><strong><span >(x)</span></strong></span><span ><span > </span></span><span >realizar controle de riscos e análise de antecedentes criminais; e</span></p>
                                    <p><span ><strong><span >(xi)</span></strong></span><span ><span > </span></span><span >cumprir com obrigações legais de coleta e compartilhamento de dados com o poder público, na forma da legislação e regulamentação aplicáveis.</span></p>
                                    <p><span ><span >Os seus dados de contato são utilizados para a UaiMobi se comunicar diretamente com você e para enviar informações relevantes, incluindo informações de transações, avisos sobre os o Aplicativo e os Serviços, ofertas especiais, divulgação de eventos, notícias, práticas recomendadas, entre outras.</span></span></p>
                                    <p><span ><span >Os seus dados financeiros são utilizados para a efetivação de pagamentos pelas corridas ou viagens realizadas e para transferência dos respectivos valores para os Motoristas, além das hipóteses de compartilhamento previstas abaixo.</span></span></p>
                                    <p><span ><span >A UaiMobi pode utilizar outras informações sobre você disponíveis a partir de fontes terceiras e combiná-las com as informações coletadas diretamente de você em conformidade com esta Política de Privacidade, para todas as finalidades previstas nesta Seção. Nessa hipótese, somente as informações coletadas diretamente pela UaiMobi de você e o resultado da combinação são abrangidos por e sujeitos a esta Política de Privacidade.</span></span></p>
                                    <p><span ><span >A UaiMobi não compartilhará nem fornecerá a terceiros as suas informações, incluindo os seus dados pessoais, conforme listados na Seção 1 acima, exceto:</span></span></p>
                                    <p><span ><strong><span >(i) </span></strong></span><span >quando você requerer ou autorizar à UaiMobi o compartilhamento;</span></p>
                                    <p><span ><strong><span >(ii)</span></strong></span><span ><span > </span></span><span >às subsidiárias, afiliadas, sociedades coligadas ou à entidade controladora da UaiMobi, para para quaisquer das finalidades previstas nesta Política de Privacidade;</span></p>
                                    <p><span ><strong><span >(iii)</span></strong></span><span ><span > </span></span><span >para viabilizar o fornecimento e a operação do Aplicativo e dos Serviços da UaiMobi de maneira adequada e na forma que você contratou;</span></p>
                                    <p><span ><strong><span >(iv)</span></strong></span><span ><span > </span></span><span >a parceiros comerciais que prestem serviços ou trabalhem em nome da UaiMobi, de acordo com as especificações e diretrizes determinadas pela UaiMobi, incluindo previsão contratual de dever de manutenção da confidencialidade das informações por esses terceiros;</span></p>
                                    <p><span ><strong><span >(v)</span></strong></span><span ><span > </span></span><span >a órgãos, autoridades e demais entes do poder público, para o atendimento da legislação e regulamentação aplicáveis ao Aplicativo, aos Serviços da UaiMobi e/ou ao serviço de transporte prestado pelos motoristas e taxistas aos passageiros;</span></p>
                                    <p><span ><strong><span >(vi)</span></strong></span><span ><span > </span></span><span >a órgãos, autoridades e demais entes do poder público, bem como a pessoas físicas ou jurídicas de natureza privada, em cumprimento de obrigação legal ou de ordem judicial;</span></p>
                                    <p><span ><strong><span >(vii)</span></strong></span><span ><span > </span></span><span >à autoridade policial ou ao representante do Ministério Público, independentemente da existência de obrigação legal específica ou ordem judicial, quando, a critério da UaiMobi, houver suspeita, flagrante ou investigação de crime com violência ou grave ameaça envolvendo usuários do Aplicativo, ou necessário para proteger direitos, propriedades ou a segurança da UaiMobi, de seus usuários ou de terceiros;</span></p>
                                    <p><span ><strong><span >(viii)</span></strong></span><span ><span > </span></span><span >para o exercício e defesa de quaisquer direitos da UaiMobi, ao seu critério, incluindo no âmbito de processos judiciais ou administrativos; e</span></p>
                                    <p><span ><strong><span >(ix)</span></strong></span><span ><span > </span></span><span >em caso de alteração da propriedade ou controle da UaiMobi ou da sua entidade controladora da UaiMobi, em razão de fusão, aquisição ou venda de ativos.</span></p>
                                    <p><span > </span></p>
                                    <p><span ><strong><span >Consentimento</span></strong></span></p>
                                    <p><span > </span></p>
                                    <p><span ><span >A UaiMobi oferece seus Serviços sujeito aos Termos e Condições de Uso e à Política de Privacidade e ela será aplicável enquanto você utilizar o Aplicativo e Serviços . </span></span></p>
                                    <p><span ><span >Quando você instala o Aplicativo e realiza seu cadastro nos Serviços você concorda com os Termos e Condições de Uso e com a Política de Privacidade. VOCÊ CONSENTE EXPRESSAMENTE COM A COLETA, ARMAZENAMENTO, USO, PROCESSAMENTO, ASSOCIAÇÃO, COMPARTILHAMENTO, DIVULGAÇÃO E OUTROS MODOS DE TRATAMENTO DAS SUAS INFORMAÇÕES, INCLUINDO DADOS PESSOAIS, SOB QUALQUER DAS FORMAS E PARA TODAS AS FINALIDADES PREVISTAS NESTA POLÍTICA DE PRIVACIDADE.  </span></span></p>
                                    <p><span ><span >Alterações futuras desta Política de Privacidade que sejam consideradas relevantes, ou seja, que reduzam ou alterem direitos atribuídos a você, serão comunicadas pela UaiMobi com antecedência prévia adequada. Nesse caso, se você não concordar com o teor da nova Política de Privacidade, as suas informações continuarão sendo tratadas em conformidade com a versão anterior pelo prazo de aviso prévio concedido, durante o qual você poderá cessar o uso do Aplicativo e dos Serviços da UaiMobi. Se você continuar utilizando o Aplicativo e/ou os Serviços da UaiMobi após o término do prazo de aviso prévio, você estará concordando integralmente com a nova Política de Privacidade, que passarão a reger o tratamento das suas informações.</span></span></p>
                                    <p><span > </span></p>
                                    <p><span ><strong><span >Exclusão de dados pessoais</span></strong></span></p>
                                    <p><span > </span></p>
                                    <p><span ><span >Se você desejar excluir seus dados pessoais sob a guarda da UaiMobi, você deve solicitar a exclusão diretamente à empresa.</span></span></p>
                                    <p><span ><span >Para proceder a essa exclusão, você deve deletar o Aplicativo e entrar em contato com a UaiMobi para solicitar que suas informações sejam definitivamente eliminadas. </span></span><span ><strong>Portanto, a desinstalação do Aplicativo não basta para a exclusão das suas informações.</strong></span></p>
                                    <p><span ><span >Em observância à legislação aplicável ou cumprimento de ordem judicial, a UaiMobi poderá manter determinados dados seus armazenados por um período não inferior a 6 (seis) meses, após o seu pedido de exclusão. Referidos dados</span></span><span ><strong> não serão anonimizados ou destruídos pela UaiMobi antes da conclusão desse prazo</strong></span><span >. </span></p>
                                    <p><span ><span >A</span></span><span ><strong> UaiMobi armazenará a sua solicitação de exclusão e, observado o prazo legal de guarda obrigatória de alguns determinados dados, providenciará a destruição ou anonimização, a critério exclusivo da empresa, das informações capazes de identificar você</strong></span><span >..</span></p>
                                    <p><span ><span >Por outro lado, caso você solicite a exclusão das suas informações mas ainda tenha alguma obrigação pendente de cumprimento com a UaiMobi, incluindo valores não pagos e saldo negativo, as suas informações não serão excluídas e permanecerão armazenadas para o fim de viabilizar a solução da pendência e a adoção de medidas visando a sua cobrança pela UaiMobi.</span></span></p>
                                    <p><span ><span >VOCÊ CONCORDA COM ESTA POLÍTICA DE PRIVACIDADE E RECONHECE QUE ELA É COMPLEMENTAR AOS TERMOS E CONDIÇÕES DE USO DA UaiMobi TECNOLOGIA LTDA. VOCÊ RECONHECE, AINDA, QUE SE APLICAM AO TRATAMENTO DOS SEUS DADOS PESSOAIS E DEMAIS INFORMAÇÕES OBJETO DESTA POLÍTICA DE PRIVACIDADE AS LEIS DO BRASIL</span></span></p>
                                    <p><span ><span >Em caso de dúvidas e sugestões, entre em contato com nossos canais de comunicação ao usuário.</span></span></p>
                                    <p> </p>
                                </div>
                            </div>
                        </div>
                    </Section>
                </div>
            </div>
            <Footer inHome={false} />
        </Fragment>

    );
};



export default about;


