import React from 'react';

import LinkHome from '../Link/Link';
import {Link} from 'react-router-dom'
const footer = ({inHome}) => {
  return (
    <footer className=''>
      <div className='container text-light pt-4'>
        <div className='row'>
          <div className='col-sm-6 col-md-6 col-lg-3 mb-5'>
            <div className='footer-title'>
              <h6>Sobre nós</h6>
            </div>
            <div className='footer-content'>
              <p>
                <small className='text-muted'>
                Somos uma empresa de Mobilidade Urbana. Assim como Uber e 99, gerenciamos o transporte de passageiros, moto-taxi e carros particulares.
          
                </small>
              </p>
              <Link to="/sobre">
                    <button
                      className=' buttons'
                    >
                      Leia mais
                  </button>
              </Link>
            </div>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-2 mb-5'>
            <div className='footer-title'>
              <h6>Links rápidos</h6>
            </div>
            <div className='footer-content'>
            {inHome? 
            <ul className='list-group quick-links'>
                <li>
                  <LinkHome target='home' offset={-120}>
                    Página Principal
                  </LinkHome>
                </li>
                <li>
                  <LinkHome target='about'>Sobre nós</LinkHome>
                </li>
                <li>
                  <LinkHome target='services'>Serviços</LinkHome>
                </li>
                <li>
                  <LinkHome target='contact'>Contate-nos</LinkHome>
                </li>
                {/* <li>
                  <Link to='/motoristas'>Motoristas</Link>
                </li> */}
              </ul>
              :
              <ul className='list-group quick-links'>
                <li>
                  <Link to='/'>Página Principal</Link>
                </li>
                <li>
                  <Link to='/sobre'>Sobre nós</Link>
                </li>
                <li>
                  <Link to='/'>Serviços</Link>
                </li>
                <li>
                  <Link to='/'>Contate-nos</Link>
                </li>
                {/* <li>
                  <Link to='/motoristas'>Motoristas</Link>
                </li>                 */}
              </ul>
              }
              
            </div>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-2 mb-5'>
            <div className='footer-title'>
              <h6>Últimas notícias</h6>
            </div>
            <div className='footer-content'>
              <a href='https://susanalitico.saude.gov.br/extensions/covid-19_html/covid-19_html.html'  
              target='self'>
                <small className='text-muted'>
                  COVID-19
                </small>
              
              </a>
              
              
            </div>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-2 mb-5'>
            <div className='footer-title'>
              <h6>Termos de uso</h6>
            </div>
              <div className='footer-content'>
            <ul className='list-group quick-links'>
                <li>
                  <Link to='/termosMotorista'>Motorista</Link>
                </li>
                <li>
                  <Link to='/termosPassageiro'>Passageiro</Link>
                </li>
              </ul>
            </div>
            
          </div>
          <div className='col-sm-6 col-md-6 col-lg-3 mb-5'>
            <div className='footer-title'>
              <h6>Contate-nos</h6>
            </div>
            <div className='footer-content'>
              <p className='text-muted'>
                <small>Barão de Cocais - MG</small>
              </p>
              
              <p className='text-muted'>
                <small>Telefones:</small> 
                <a href="https://api.whatsapp.com/send?phone=5531997577475&text=Ol%C3%A1,%20preciso%20de%20uma%20informa%C3%A7%C3%A3o!"
                  target='blank'>
                    <small className='text-muted'> (31) 99757-7475</small>
                </a>
                
              </p>
              <p className='text-muted'>
                <small>E-mail:</small> 
                <a href="mailto:appuaimobi@gmail.com?subject=Envio%20de%20mensagem"
                  target='blank'>
                    <small className='text-muted'> appuaimobi@gmail.com</small>
                </a>
                
              </p>
              <p className='text-muted'>
                <small>CNPJ: 36.752.374/0001-00</small>
              </p>
              <div className='social-media mt-4'>
                <a href='https://www.facebook.com/uaimobi/'
                target='blank' className='text-light'>
                  <i className='fab fa-facebook-f mr-4' />
                </a>
                
                <a href='https://www.instagram.com/uaimobi/'
                target='self' 
                className='text-light'>
                  <i className='fab fa-instagram mr-4' />
                </a>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bottom-footer pt-3 pb-3 text-center'>
        <small>© Todos os direitos reservados a UaiMobi - 2021 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      </small>
        <Link to='/politicaDePrivacidade'>
          <small>Politica de privacidade</small>
        </Link>
        
      </div>
    </footer>
  );
};

export default footer;
