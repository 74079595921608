import React from 'react';

import Section from '../../../HOC/Section';
import bg from '../../../assets/img/home_bg.jpg';

const Citys = () => {
  return (
    <Section id='citys'>
      <div
        className='citys-container'
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div  className='container pt-5 pb-4'>
          <div className='row'>
            <div className=' col-sm-6 col-md-3 text-center mb-4 '>
              <div className='mb-2 citys-icon'>
                <i className="fas fa-map-marked-alt"></i>
              </div>
              <h2 className='citys-counter text-light'>Barão de Cocais</h2>
            </div>
            <div className=' col-sm-6 col-md-3 text-center mb-4' >
              <div className='mb-2 citys-icon'>
                <i className='fas fa-map-marked-alt' />
              </div>
              <h4 className='citys-counter text-light'>Santa Bárbara</h4>

              <a
                href='https://transparencia.santabarbara.mg.gov.br/abrir_arquivo.aspx/Decreto_4619_2020?cdLocal=5&arquivo={AC40D8DB-A58A-50D0-D4CC-E80D267403DB}.pdf'
                target='blank'
              >
                <font color='white'>
                  Decreto Regulamentador
                </font>
              </a>
            </div>
            <div className=' col-sm-6 col-md-3 text-center mb-4 '>
              <div className='mb-2 citys-icon'>
                <i className='fas fa-map-marked-alt' />
              </div>
              <h4 className='citys-counter text-light'>Catas Altas</h4>
            </div>
            <div className=' col-sm-6 col-md-3 text-center mb-4 '>
              <div className='mb-2 citys-icon'>
                <i className="fas fa-map-marked-alt"></i>
              </div>
              <h2 className='citys-counter text-light'>Em breve, novas cidades</h2>
            </div>
          </div>
        </div>

      </div>
    </Section>
  );
};

export default Citys;
