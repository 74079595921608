import React, { Fragment } from 'react';

import Header from './UI/Header/Header';
import Footer from './UI/Footer/Footer';
import Sections from './sections/Sections';

const Index = () => {
  return (
    <Fragment>
      <Header inHome={true}/>
      <main>
        <Sections />
      </main>
      <Footer inHome={true}/>
    </Fragment>
  );
};

export default Index;
